import React from "react";
import LeagueTitle from "./LeagueTitle";
import ListBall from "./ListBall";

export default function RowGTB(props) {
  const { pt, atChange , liveTB, dataLg, dataSp, priceSp , SportsSelect,
    SportsPriceSelect , sortTable , teamFev , onTeamFav , setBusketBet , busketBetPar , setBusketBetPar } = props;
  //console.log(dataSp);
  /*if(liveTB===1){
    console.log(dataSp);
    console.log("##########");
  }*/

  dataSp.sort(dynamicSortMultiple("6 Asc"));
  var tlive_bid = 0;
  var tlive_bnow = "#fdddd2";

  var ttoday_bid = 0;
  var ttoday_bnow = "#f8f8f8";

  var sppdt = [];
  for (var pop in dataSp) {
    var dpsp = dataSp[pop];
    var dpspx = priceSp[dpsp[0]];
    
    if(dpspx!==undefined){
      dpspx.sort(dynamicSortMultiple("1 Asc"));
      for (var popx in dpspx) {
        var dp_obj = {};
        dp_obj["data"] = dpsp;
        dp_obj["price"] = dpspx[popx];

        var spp = dpspx[popx];

        if(SportsPriceSelect===2){
          if (spp[19] !== 0 && spp[20] !== 0) {
            sppdt.push(dp_obj);
          }
        }else if(SportsPriceSelect===3){
          if ((spp[15]>0.00 && spp[16]>0.00 && spp[17]>0.00) || (spp[31]>0.00 && spp[32]>0.00 && spp[33]>0.00)) {
            sppdt.push(dp_obj);
          }
        }else if(SportsPriceSelect===4){
          //if (spp[2]===2) {
            sppdt.push(dp_obj);
          //}
        }else{
          sppdt.push(dp_obj);
        }
      }
    }
  }

  const favAll = () => {
    var list_fav = [];
    var list_fav_no = [];

    for (var pop in dataSp) {
      var dpsp = dataSp[pop];
      if(!inArray(String(dpsp[0]) , teamFev)){
        list_fav_no.push(dpsp[0])
      }
    }

    if(list_fav_no.length>0){
      onTeamFav(list_fav_no);
    }else{
      for (var pop in dataSp) {
        var dpsp = dataSp[pop];
        list_fav.push(dpsp[0])
      }
      onTeamFav(list_fav);
    }

    
  };

  const favOne = (value) => {
    var list_fav = [];
    list_fav.push(value)
    onTeamFav(list_fav);
  };

  //console.log(sppdt);

  return (
    <div style={{ display: "table-row-group" }}>
      <LeagueTitle liveTB={liveTB} pt={pt} dataLg={dataLg} SportsSelect={SportsSelect} SportsPriceSelect={SportsPriceSelect} onFavAll={favAll} />
      {sppdt.map(function (tlist, j) {
        //tlist["b_add"] = 1;
        //var priceSp = [1];

        var tldata = tlist.data;

        if (liveTB === 1) {
          if (tlive_bid !== tldata[0]) {
            tlive_bid = tldata[0];
            if (tlive_bnow === "#fdddd2") {
              tlive_bnow = "#fbcbbd";
            } else {
              tlive_bnow = "#fdddd2";
            }
          }
        } else {
          if (ttoday_bid !== tldata[0]) {
            ttoday_bid = tldata[0];
            if (ttoday_bnow === "#f8f8f8") {
              ttoday_bnow = "#e8e8e8";
            } else {
              ttoday_bnow = "#f8f8f8";
            }
          }
        }

        

        return <ListBall liveTB={liveTB} pt={pt} bgColor={liveTB === 1 ? tlive_bnow : ttoday_bnow} dBall={tldata} dPrice={tlist.price} key={j} SportsSelect={SportsSelect} SportsPriceSelect={SportsPriceSelect} atChange={atChange} sortTable={sortTable} teamFev={teamFev} onFavOne={favOne} setBusketBet={setBusketBet} busketBetPar={busketBetPar} setBusketBetPar={setBusketBetPar} />;
      
      })}
    </div>
  );
}

function dynamicSortMultiple() {
  var props = [];
  /*Let's separate property name from ascendant or descendant keyword*/
  for (var i = 0; i < arguments.length; i++) {
    var splittedArg = arguments[i].split(/ +/);
    props[props.length] = [
      splittedArg[0],
      splittedArg[1] ? splittedArg[1].toUpperCase() : "ASC",
    ];
  }
  return function (obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length;
    /*Cycle on values until find a difference!*/
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i][0], props[i][1])(obj1, obj2);
      i++;
    }
    return result;
  };
}

function dynamicSort(property, isAscDesc) {
  return function (obj1, obj2) {
    if (isAscDesc === "DESC") {
      return obj1[property] > obj2[property]
        ? -1
        : obj1[property] < obj2[property]
        ? 1
        : 0;
    }
    /*else, if isAscDesc==="ASC"*/
    return obj1[property] > obj2[property]
      ? 1
      : obj1[property] < obj2[property]
      ? -1
      : 0;
  };
}
function inArray(needle, haystack) {
  var length = haystack.length;
  for(var i = 0; i < length; i++) {
      if(haystack[i] === needle) return true;
  }
  return false;
}