import React from "react";
import { usePrefixContext } from "../../../context/PrefixContext";
import { useTranslation } from "react-i18next";

export default function ListBall(props) {
  const {
    dBall, bgColor
  } = props;

  const prefixContext = usePrefixContext();
  const { t } = useTranslation();
  var b_name1 = {};
  b_name1["CN"] = dBall[32] === "" ? dBall[26] : dBall[32];
  b_name1["EN"] = dBall[26];
  b_name1["ES"] = dBall[26];
  b_name1["FR"] = dBall[26];
  b_name1["IN"] = dBall[26];
  b_name1["JP"] = dBall[26];
  b_name1["KH"] = dBall[26];
  b_name1["KR"] = dBall[26];
  b_name1["LA"] = dBall[26];
  b_name1["MM"] = dBall[26];
  b_name1["PT"] = dBall[26];
  b_name1["TH"] = dBall[29] === "" ? dBall[26] : dBall[29];
  b_name1["YN"] = dBall[26];
  b_name1["RU"] = dBall[26];

  var b_name2 = {};
  b_name2["CN"] = dBall[33] === "" ? dBall[27] : dBall[33];
  b_name2["EN"] = dBall[27];
  b_name2["ES"] = dBall[27];
  b_name2["FR"] = dBall[27];
  b_name2["IN"] = dBall[27];
  b_name2["JP"] = dBall[27];
  b_name2["KH"] = dBall[27];
  b_name2["KR"] = dBall[27];
  b_name2["LA"] = dBall[27];
  b_name2["MM"] = dBall[27];
  b_name2["PT"] = dBall[27];
  b_name2["TH"] = dBall[30] === "" ? dBall[27] : dBall[30];
  b_name2["YN"] = dBall[27];
  b_name2["RU"] = dBall[27];

  var date_data_1 = new Date(dBall[2] * 1000);
  var d_data = "0" + date_data_1.getDate();
  var m_data = "0" + (date_data_1.getMonth() + 1);
  var hours_data_1 = "0" + date_data_1.getHours();
  var minutes_data_1 = "0" + date_data_1.getMinutes();
  var formattedTime = m_data.substr(-2)+"/"+d_data.substr(-2)+" "+hours_data_1.substr(-2) + ":" + minutes_data_1.substr(-2);

  var score_1h = "-";
  var score_ft = "-";

  if(dBall[20]===3){
    score_1h = <font color="red">{t("REFUND")}</font>;
    score_ft = <font color="red">{t("REFUND")}</font>;
  }else if(dBall[20]===4){
    score_1h = <font color="red">{t("REFUND")}</font>;
    score_ft = (dBall[18]!=="" ? dBall[18].replace("-", " - ") : "-");
  }else if(dBall[20]===5){
    score_1h = (dBall[19]!=="" ? dBall[19].replace("-", " - ") : "-");
    score_ft = <font color="red">{t("REFUND")}</font>;
  }else{
    score_1h = (dBall[19]!=="" ? dBall[19].replace("-", " - ") : "-");
    score_ft = (dBall[18]!=="" ? dBall[18].replace("-", " - ") : "-");
  }

  var t1_score = 0;
  var t2_score = 0;
  if(dBall[20]===1 && dBall[18]!==""){
    var exsc = dBall[18].split("-");
    t1_score = parseInt(exsc[0]);
    t2_score = parseInt(exsc[1]);
  }

  return (
    <tr className={bgColor} align="center">
      <td className="GridColor">{formattedTime}</td>
      <td className="GridColor" align="left" style={{ width: 400 }}>
        <span className={"GridScore_"+(t1_score>t2_score ? "B" : "N")}>{b_name1[prefixContext.getCurrentLanguage()]}</span>
        <br />
        <span className={"GridScore_"+(t2_score>t1_score ? "B" : "N")}>{b_name2[prefixContext.getCurrentLanguage()]}</span>
      </td>
      <td className="GridColor">
        <span className="GridScore">{score_1h}</span>
      </td>
      <td className="GridColor">
        <span className="GridScore">{score_ft}</span>
      </td>
    </tr>
  );
}
