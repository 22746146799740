import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

var zbBlink = {};

export default function ColumOE(props) {
  const { dPrice, b_hf, atChange, SportsPriceSelect, sortTable, setBusketBet } = props;
  const prevBall = usePrevious(dPrice);
  const prevSort = usePrevious(sortTable);
  const { t } = useTranslation();

  if (prevBall !== undefined && atChange === false && sortTable === prevSort) {
    if (dPrice[0] === prevBall[0]) {
      if (dPrice[19] !== prevBall[19] || dPrice[20] !== prevBall[20]) {
        zbBlink["ft_" + dPrice[0] + "_" + dPrice[1] + "_oe"] = new Date();
      } else {
        var nb = zbBlink["ft_" + dPrice[0] + "_" + dPrice[1] + "_oe"];
        var now_t = new Date();
        var d_time = getMinutesBetweenDates(nb, now_t);
        if (d_time > 7) {
          delete zbBlink["ft_" + dPrice[0] + "_" + dPrice[1] + "_oe"];
        }
      }

      if (dPrice[35] !== prevBall[35] || dPrice[36] !== prevBall[36]) {
        zbBlink["1h_" + dPrice[0] + "_" + dPrice[1] + "_oe"] = new Date();
      } else {
        var nb = zbBlink["1h_" + dPrice[0] + "_" + dPrice[1] + "_oe"];
        var now_t = new Date();
        var d_time = getMinutesBetweenDates(nb, now_t);
        if (d_time > 7) {
          delete zbBlink["1h_" + dPrice[0] + "_" + dPrice[1] + "_oe"];
        }
      }
    }
  }

  var b_odd = "";
  var b_even = "";
  if (b_hf === 1) {
    if (dPrice[35] !== 0 && dPrice[36] !== 0) {
      b_odd = formatMoney(dPrice[35], 2);
      b_even = formatMoney(dPrice[36], 2);
    }
  } else {
    if (dPrice[19] !== 0 && dPrice[20] !== 0) {
      b_odd = formatMoney(dPrice[19], 2);
      b_even = formatMoney(dPrice[20], 2);
    }
  }

  const selectBet = (t) => {
    var sp_select = {};
    sp_select["BetHF"] = b_hf;
    sp_select["BetAdd"] = dPrice[1];
    sp_select["BetMatch"] = dPrice[0];
    sp_select["BetType"] = t;
    sp_select["id_type"] = (b_hf === 1 ? dPrice[34] : dPrice[18]);
    sp_select["id_type_num"] = (b_hf === 1 ? 8 : 4);
    setBusketBet(sp_select);
  };

  if (SportsPriceSelect === 2) {
    return (
      <>
        <div
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center",
          }}
          className={
            "HDPBG_GridItem" +
            (zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_oe"]>0 ? " NewOdds2" : "")
          }
        >
          <span
            style={{ cursor: "pointer", textDecoration: "none" }}
            className={parseFloat(b_odd) < 0.0 ? "NegOdds" : "PosOdds"}
          >
            <label onClick={() => selectBet("odd")}>{b_odd}</label>
          </span>
        </div>
        <div
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center",
          }}
          className={
            "HDPBG_GridItem" +
            (zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_oe"]>0 ? " NewOdds2" : "")
          }
        >
          <span
            style={{ cursor: "pointer", textDecoration: "none" }}
            className={parseFloat(b_even) < 0.0 ? "NegOdds" : "PosOdds"}
          >
            <label onClick={() => selectBet("even")}>{b_even}</label>
          </span>
        </div>
      </>
    );
  }

  return (
    <div
      style={{
        display: "table-cell",
        width: "45px",
        textAlign: "center",
      }}
      className=""
    >
      <div style={{ display: "table" }} className="StrStyleSoc">
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              width: "13px",
              textAlign: "left",
            }}
            className="Heading6"
          >
            &nbsp;
            <span className="Heading6">
              <label onClick={() => selectBet("odd")}>{b_odd !== "" ? t("odd") : ""}</label>
            </span>
          </div>
          <div
            style={{
              display: "table-cell",
              width: "37px",
              textAlign: "right",
            }}
            className={
              zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_oe"]>0 ? " NewOdds2" : ""
            }
          >
            <span className={parseFloat(b_odd) < 0.0 ? "NegOdds" : "PosOdds"}>
              <label onClick={() => selectBet("odd")}>{b_odd}</label>
            </span>
            &nbsp;
          </div>
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "left",
            }}
            className="Heading6"
          >
            &nbsp;
            <span className="Heading6">
              <label onClick={() => selectBet("even")}>{b_even !== "" ? t("even") : ""}</label>
            </span>
          </div>
          <div
            style={{
              display: "table-cell",
              textAlign: "right",
            }}
            className={
              zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_oe"]>0 ? " NewOdds2" : ""
            }
          >
            <span className={parseFloat(b_even) < 0.0 ? "NegOdds" : "PosOdds"}>
              <label onClick={() => selectBet("even")}>{b_even}</label>
            </span>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  );
}

function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
      Math.abs(n - i)
        .toFixed(c)
        .slice(2)
      : "")
  );
}


function getMinutesBetweenDates(startDate, endDate) {
  var diffMs = endDate - startDate;
  var diffSec = diffMs / 1000;
  return diffSec;
}