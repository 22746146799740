import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import img_FavOri from "../../../Images/FavOri.gif";
import img_FavAdd from "../../../Images/FavAdd.gif";

import img_redcard1 from "../../../Images/redcard1.gif";
import img_redcard2 from "../../../Images/redcard2.gif";
import img_redcard3 from "../../../Images/redcard3.gif";
import img_redcard4 from "../../../Images/redcard4.gif";
import img_redcard5 from "../../../Images/redcard5.gif";

export default function ColumMatch(props) {
  const { liveTB, pt , b_add , b_id , b_big , b_hdc , b_name1 , b_name2 , card_r, SportsSelect,
    SportsPriceSelect , teamFev , onFavOne } = props;
  const { t } = useTranslation();
  var num_redCard_1 = 0;
  var num_redCard_2 = 0;
  if (liveTB === 1) {
    if(card_r!==""){
      const redArray = card_r.split(",");
      num_redCard_1 = parseInt(redArray[0]);
      num_redCard_2 = parseInt(redArray[1]);
    }
    
  }
  /*if(b_id===7170888){
    console.log(b_name2 , b_add);
  }*/
  return (
    <div
      style={{ display: "table-cell", paddingLeft: "5px" , verticalAlign: (pt===2 ? "" : "middle") }}
      className="GridItemTD"
    >
      {b_add === 1 ? (
        <div style={{ display: "table" }} className="StrStyleSoc">
          <div style={{ display: "table-row" }}>
            <div
              style={{
                display: "table-cell",
                width: (SportsSelect===1 ? "435" : (SportsPriceSelect===2 || SportsPriceSelect===3 ? "290" : SportsPriceSelect===4 ? "236" : ((pt===1 || pt===3) ? liveTB === 1 ? pt===3 ? "228" : "278" : "228" : "152"))) + "px",
              }}
            >
              <span className={(b_big===1 && b_hdc!=="0" && b_hdc!=="" ? "Give" : "Take")}>{b_name1}</span>&nbsp;
              {num_redCard_1 > 0 ? (
                <img
                  alt=""
                  className="CssImgRC"
                  src={
                    num_redCard_1 === 1
                      ? img_redcard1
                      : num_redCard_1 === 2
                      ? img_redcard2
                      : num_redCard_1 === 3
                      ? img_redcard3
                      : num_redCard_1 === 4
                      ? img_redcard4
                      : num_redCard_1 === 5
                      ? img_redcard5
                      : ""
                  }
                />
              ) : (
                ""
              )}
              <br />
              <span className={(b_big===2 && b_hdc!=="0" && b_hdc!=="" ? "Give" : "Take")}>{b_name2}</span>&nbsp;
              {num_redCard_2 > 0 ? (
                <img
                  alt=""
                  className="CssImgRC"
                  src={
                    num_redCard_2 === 1
                      ? img_redcard1
                      : num_redCard_2 === 2
                      ? img_redcard2
                      : num_redCard_2 === 3
                      ? img_redcard3
                      : num_redCard_2 === 4
                      ? img_redcard4
                      : num_redCard_2 === 5
                      ? img_redcard5
                      : ""
                  }
                />
              ) : (
                ""
              )}
              {((pt===1 || pt===3) && (SportsPriceSelect===1 || SportsPriceSelect===4) && SportsSelect===6 ? <><br /><span className="Draw">{t("draw")}</span></> : "")}
            </div>
            {SportsPriceSelect===1 && SportsSelect===6 ? <div
              style={{
                display: "table-cell",
                width: "15px",
                verticalAlign: "middle",
                textAlign: "center",
              }}
            >
              <Link to="/" onClick={() => onFavOne(b_id)}><img
                fav="0"
                src={(inArray(String(b_id) , teamFev) ? img_FavAdd : img_FavOri)}
                border="0"
                align="absmiddle"
                width="13px"
                height="13px"
                alt=""
              /></Link>
            </div> : null}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
function inArray(needle, haystack) {
  var length = haystack.length;
  for(var i = 0; i < length; i++) {
      if(haystack[i] === needle) return true;
  }
  return false;
}