import React, { useState, useEffect } from "react";
import { useAuthContext } from "./context/AuthContext";
import { usePrefixContext } from './context/PrefixContext';
import io from "socket.io-client";
import Top from "./components/Top";
import Left from "./components/Left";
import Main from "./components/Main";
import Right from "./components/Right";

export default function WebMain() {
  const [socketId, setSocketId] = useState("");
  const [sportData, setSportData] = useState("");
  const [sportPrice, setSportPrice] = useState("");
  const [toogle_top, setToogle_top] = useState(false);
  const [toogle_left, setToogle_left] = useState(false);
  const [atChange, setAtChange] = useState(false);
  const [SportsSelect, setSportsSelect] = useState(6);
  const [SportsPriceSelect, setSportsPriceSelect] = useState(1);
  const [toogle_live, setToogle_live] = useState(false);
  const [busketBet, setBusketBet] = useState({});
  const [busketBetPar, setBusketBetPar] = useState([]);

  const [PageMain, setPageMain] = useState("BallStep");
  const [vShow, setVShow] = useState(0);

  const authContext = useAuthContext();
  const { prefix , getCurrentLanguage } = usePrefixContext();

  useEffect(() => {
    //setSocketId(socket.id);
    //console.log(socketId);
    //console.log(socketD);
    if (socketId === "") {
      //const {token} = localStorage.getItem("a-token");
      const socket = io(process.env.REACT_APP_WS_HOST, {
        secure: true,
        transports: ["polling" , "websocket"],
        query: {"token":localStorage.getItem("a-token")}
      });

      var spData = {};
      var spPrice = [];
      spPrice[0] = {};
      spPrice[1] = {};

      socket.on("me", (id) => {
        //console.log(socket);
        setSocketId(id);
      });

      socket.on("dc", () => {
        socket.disconnect();
        spData = {};
        spPrice = [];
        spPrice[0] = {};
        spPrice[1] = {};
        authContext.logOut();
      });

      socket.on("mtn", () => {
        socket.disconnect();
        spData = {};
        spPrice = [];
        spPrice[0] = {};
        spPrice[1] = {};
        authContext.logOut();
      });

      socket.on("dc2", () => {
        spData = {};
        spPrice = [];
        spPrice[0] = {};
        spPrice[1] = {};
        //socket.disconnect();
      });

      /*socket.on("disconnect", () => {
        spData = {};
        spPrice = [];
        spPrice[0] = {};
        spPrice[1] = {};
        authContext.logOut();
      });*/

      

      socket.on("getAllData", (sportData) => {
        spData = sportData;
        setSportData(JSON.stringify(spData));
      });
      socket.on("getAllPrice", (sportPrice) => {
        (async () => {
          try {
            spPrice = sportPrice;
            //console.log(sportPrice);
            setSportPrice(JSON.stringify(spPrice));
            await sleep(500);
            setAtChange(false);
          } catch (err) {

          }
        })();
      });
      //console.log(socket.toString());
      //setSocketId(socket.id);
      socket.on("insertData", (data_db_ball) => {
        //console.log("add" , data_db_ball);
        spData[data_db_ball.key] = data_db_ball.data;
        //setSportData(JSON.stringify(spData));
      });

      socket.on("updateData", (data_db_ball) => {
        if (spData[data_db_ball.key] !== undefined) {
          var ddu = data_db_ball.data;
          for (var iux in ddu) {
            spData[data_db_ball.key][iux] = ddu[iux];
          }
          //setSportData(JSON.stringify(spData));
        }
      });

      socket.on("insertPrice", (data_db_price) => {
        //console.log("add" , data_db_price);
        if (spPrice[0][data_db_price.key] === undefined) {
          spPrice[0][data_db_price.key] = [];
        } else {
          for (var ddpx in spPrice[0][data_db_price.key]) {
            var dpxg = spPrice[0][data_db_price.key][ddpx];
            if (dpxg[1] === data_db_price.data[1]) {
              spPrice[0][data_db_price.key].splice(ddpx, 1);
            }
          }
        }

        spPrice[0][data_db_price.key].push(data_db_price.data);
      });

      socket.on("updatePrice", (data_db_price) => {
        var kpps = data_db_price.key.split("_");

        if (spPrice[0][kpps[0]] !== undefined) {
          for (var ispx in spPrice[0][kpps[0]]) {
            var daispx = spPrice[0][kpps[0]][ispx];
            if (daispx[1] === parseInt(kpps[1])) {
              var ddu = data_db_price.data;
              for (var iux in ddu) {
                spPrice[0][kpps[0]][ispx][iux] = ddu[iux];
              }
              break;
            }
          }
        }
      });

      socket.on("deletePrice", (data_db_price) => {
        //console.log("add" , data_db_ball);
        var kpps = data_db_price.split("_");
        if (spPrice[0][kpps[0]] !== undefined) {
          for (var ispx in spPrice[0][kpps[0]]) {
            var daispx = spPrice[0][kpps[0]][ispx];
            if (daispx[1] === parseInt(kpps[1])) {
              spPrice[0][kpps[0]].splice(ispx, 1);
              break;
            }
          }
        }
      });

      socket.on("insertPriceSTEP", (data_db_price) => {
        //console.log("add" , data_db_price);
        if (spPrice[1][data_db_price.key] === undefined) {
          spPrice[1][data_db_price.key] = [];
        } else {
          for (var ddpx in spPrice[1][data_db_price.key]) {
            var dpxg = spPrice[1][data_db_price.key][ddpx];
            if (dpxg[1] === data_db_price.data[1]) {
              spPrice[1][data_db_price.key].splice(ddpx, 1);
            }
          }
        }

        spPrice[1][data_db_price.key].push(data_db_price.data);
      });

      socket.on("updatePriceSTEP", (data_db_price) => {
        var kpps = data_db_price.key.split("_");

        if (spPrice[1][kpps[0]] !== undefined) {
          for (var ispx in spPrice[1][kpps[0]]) {
            var daispx = spPrice[1][kpps[0]][ispx];
            if (daispx[1] === parseInt(kpps[1])) {
              var ddu = data_db_price.data;
              for (var iux in ddu) {
                spPrice[1][kpps[0]][ispx][iux] = ddu[iux];
              }
              break;
            }
          }
        }
      });

      socket.on("deletePriceSTEP", (data_db_price) => {
        //console.log("add" , data_db_ball);
        var kpps = data_db_price.split("_");
        if (spPrice[1][kpps[0]] !== undefined) {
          for (var ispx in spPrice[1][kpps[0]]) {
            var daispx = spPrice[1][kpps[0]][ispx];
            if (daispx[1] === parseInt(kpps[1])) {
              spPrice[1][kpps[0]].splice(ispx, 1);
              break;
            }
          }
        }
      });
      
      const token_frist = localStorage.getItem("a-token");
      setInterval(() => {
        setSportData(JSON.stringify(spData));
        setSportPrice(JSON.stringify(spPrice));
        const token = localStorage.getItem("a-token");
        //console.log(token , token_frist);
        if(token===null){
          authContext.logOut();
        }
        if(token!==token_frist){
          spData = {};
          spPrice = [];
          spPrice[0] = {};
          spPrice[1] = {};
        }
      }, 1000);
    }

    return () => {
      //disconnectSocket();
    };
  }, [socketId]);

  //console.log(sportData);

  /*if(sportDataUpdate!=={}){
    //console.log(sportData[sportDataUpdate.key]);
  }*/

  const handleToogle_top = () => {
    if (toogle_top === false) {
      setToogle_top(true);
    } else {
      setToogle_top(false);
    }
  };

  const handleToogle_left = () => {
    if (toogle_left === false) {
      setToogle_left(true);
    } else {
      setToogle_left(false);
    }
  };

  const handleSportsSelect = (value) => {
    //console.log("SS" , value , SportsSelect);
    if (value !== SportsSelect) {
      setSportsSelect(value);
    }
    if (PageMain !== "BallStep") {
      setPageMain("BallStep");
    }
  };

  const handleSportsPriceSelect = (value) => {
    if (value !== SportsPriceSelect) {
      setSportsPriceSelect(value);
    }
    if (PageMain !== "BallStep") {
      setPageMain("BallStep");
    }
  };

  const handleClickOpenLive = (value) => {
    if (value !== toogle_live) {
      setToogle_live(value);
    }
    if (PageMain !== "BallStep") {
      setPageMain("BallStep");
    }
  };

  const handlePageMain = (value) => {
    //console.log("SS" , value , SportsSelect);
    if (value !== PageMain) {
      setPageMain(value);
    }
  };

  const selectBet = async (value) => {
    try {
      setBusketBetPar([]);
      if(value==="clear"){
        setBusketBet({});
        return false;
      }
      //const res = await api.post(`spDataPrice`, value);
      //if(res.data.status===1){
        var zbData = JSON.parse(sportData);
        var resData = value;
        //console.log(resData);
        if(zbData[resData.BetMatch]!==undefined){
          resData["MatchData"] = zbData[resData.BetMatch];
          setBusketBet(resData);
        }
      //}
    } catch (error) {
       alert("Error");
    }
  };

  const selectBetPar = async (value) => {
    try {
      setBusketBet({});
      if(value==="clear"){
        setBusketBetPar([]);
        return false;
      }
      var listBPar = busketBetPar;
      var zbData = JSON.parse(sportData);
      //console.log(JSON.stringify(listBPar));
      if(listBPar.length===0){
        var parBetData = [];

        var vData = value;
        var dBall = zbData[vData.BetMatch];
        var b_name1 = {};
        b_name1["CN"] = (dBall[32]==="" ? dBall[26] : dBall[32]);
        b_name1["EN"] = dBall[26];
        b_name1["ES"] = dBall[26];
        b_name1["FR"] = dBall[26];
        b_name1["IN"] = dBall[26];
        b_name1["JP"] = dBall[26];
        b_name1["KH"] = dBall[26];
        b_name1["KR"] = dBall[26];
        b_name1["LA"] = dBall[26];
        b_name1["MM"] = dBall[26];
        b_name1["PT"] = dBall[26];
        b_name1["TH"] = (dBall[29]==="" ? dBall[26] : dBall[29]);
        b_name1["YN"] = dBall[26];
        b_name1["RU"] = dBall[26];

        var b_name2 = {};
        b_name2["CN"] = (dBall[33]==="" ? dBall[27] : dBall[33]);
        b_name2["EN"] = dBall[27];
        b_name2["ES"] = dBall[27];
        b_name2["FR"] = dBall[27];
        b_name2["IN"] = dBall[27];
        b_name2["JP"] = dBall[27];
        b_name2["KH"] = dBall[27];
        b_name2["KR"] = dBall[27];
        b_name2["LA"] = dBall[27];
        b_name2["MM"] = dBall[27];
        b_name2["PT"] = dBall[27];
        b_name2["TH"] = (dBall[30]==="" ? dBall[27] : dBall[30]);
        b_name2["YN"] = dBall[27];
        b_name2["RU"] = dBall[27];

        vData["Home"] = b_name1[getCurrentLanguage()];
        vData["Away"] = b_name2[getCurrentLanguage()];
        vData["Score"] = dBall[21];
        vData["Zone"] = dBall[5];
        vData["IsRun"] = dBall[10] > 0 ? true : false;
        vData["betStatus"] = 1;
        vData["betBlink"] = 0;

        parBetData.push(vData);
        setBusketBetPar(parBetData);
        
      }else{
        var have_par = 0;
        for(var ipar in listBPar){
          var inPar = listBPar[ipar];
          /*if(inPar.betStatus===0){
            listBPar.splice(ipar, 1);
            continue;
          }*/
         //console.log(JSON.stringify(inPar) , JSON.stringify(value));
          if(inPar.BetMatch===value.BetMatch && inPar.BetType===value.BetType && inPar.BetHF===value.BetHF && inPar.BetAdd===value.BetAdd && inPar.id_type===value.id_type && inPar.id_type_num===value.id_type_num){
            have_par = 1;
            //console.log("have");
            listBPar.splice(ipar, 1);
            continue;
          }
          if(inPar.BetMatch===value.BetMatch){
            have_par = 1;
            var vData = value;
            var dBall = zbData[vData.BetMatch];
            var b_name1 = {};
            b_name1["CN"] = (dBall[32]==="" ? dBall[26] : dBall[32]);
            b_name1["EN"] = dBall[26];
            b_name1["ES"] = dBall[26];
            b_name1["FR"] = dBall[26];
            b_name1["IN"] = dBall[26];
            b_name1["JP"] = dBall[26];
            b_name1["KH"] = dBall[26];
            b_name1["KR"] = dBall[26];
            b_name1["LA"] = dBall[26];
            b_name1["MM"] = dBall[26];
            b_name1["PT"] = dBall[26];
            b_name1["TH"] = (dBall[29]==="" ? dBall[26] : dBall[29]);
            b_name1["YN"] = dBall[26];
            b_name1["RU"] = dBall[26];

            var b_name2 = {};
            b_name2["CN"] = (dBall[33]==="" ? dBall[27] : dBall[33]);
            b_name2["EN"] = dBall[27];
            b_name2["ES"] = dBall[27];
            b_name2["FR"] = dBall[27];
            b_name2["IN"] = dBall[27];
            b_name2["JP"] = dBall[27];
            b_name2["KH"] = dBall[27];
            b_name2["KR"] = dBall[27];
            b_name2["LA"] = dBall[27];
            b_name2["MM"] = dBall[27];
            b_name2["PT"] = dBall[27];
            b_name2["TH"] = (dBall[30]==="" ? dBall[27] : dBall[30]);
            b_name2["YN"] = dBall[27];
            b_name2["RU"] = dBall[27];

            vData["Home"] = b_name1[getCurrentLanguage()];
            vData["Away"] = b_name2[getCurrentLanguage()];
            vData["Score"] = dBall[21];
            vData["Zone"] = dBall[5];
            vData["IsRun"] = dBall[10] > 0 ? true : false;
            vData["betStatus"] = 1;
            vData["betBlink"] = 0;
            listBPar[ipar] = vData;
            //console.log(value);
          }
        }
        //console.log(JSON.stringify(listBPar));
        if(have_par===0 && listBPar.length<12){
          var parBetData = busketBetPar;
          
          var vData = value;
          var dBall = zbData[vData.BetMatch];
          var b_name1 = {};
          b_name1["CN"] = (dBall[32]==="" ? dBall[26] : dBall[32]);
          b_name1["EN"] = dBall[26];
          b_name1["ES"] = dBall[26];
          b_name1["FR"] = dBall[26];
          b_name1["IN"] = dBall[26];
          b_name1["JP"] = dBall[26];
          b_name1["KH"] = dBall[26];
          b_name1["KR"] = dBall[26];
          b_name1["LA"] = dBall[26];
          b_name1["MM"] = dBall[26];
          b_name1["PT"] = dBall[26];
          b_name1["TH"] = (dBall[29]==="" ? dBall[26] : dBall[29]);
          b_name1["YN"] = dBall[26];
          b_name1["RU"] = dBall[26];

          var b_name2 = {};
          b_name2["CN"] = (dBall[33]==="" ? dBall[27] : dBall[33]);
          b_name2["EN"] = dBall[27];
          b_name2["ES"] = dBall[27];
          b_name2["FR"] = dBall[27];
          b_name2["IN"] = dBall[27];
          b_name2["JP"] = dBall[27];
          b_name2["KH"] = dBall[27];
          b_name2["KR"] = dBall[27];
          b_name2["LA"] = dBall[27];
          b_name2["MM"] = dBall[27];
          b_name2["PT"] = dBall[27];
          b_name2["TH"] = (dBall[30]==="" ? dBall[27] : dBall[30]);
          b_name2["YN"] = dBall[27];
          b_name2["RU"] = dBall[27];

          vData["Home"] = b_name1[getCurrentLanguage()];
          vData["Away"] = b_name2[getCurrentLanguage()];
          vData["Score"] = dBall[21];
          vData["Zone"] = dBall[5];
          vData["IsRun"] = dBall[10] > 0 ? true : false;
          vData["betStatus"] = 1;
          vData["betBlink"] = 0;

          parBetData.push(vData);
          setBusketBetPar(parBetData);
        }else{
          setBusketBetPar(listBPar);
        }
      }
      
      //var parBetData = [];
      //parBetData.push(value);
      //setBusketBetPar(parBetData);
      //console.log(res);
    } catch (error) {
       alert("Error");
    }
  };


  return (
    <>
      <Top
        toogle_top={toogle_top}
        onToogle_top={handleToogle_top}
        onToogle_left={handleToogle_left}
        onPageMain={handlePageMain}
        setVShow={setVShow}
        onSportsSelect={handleSportsSelect}
        onSportsPriceSelect={handleSportsPriceSelect}
        onToogle_live={handleClickOpenLive}
      />
      <div
        className={
          "container" +
          (toogle_top === true ? " container_toogle_top" : "") +
          (toogle_left === true ? " container_toogle_left" : "")
        }
      >
        <Left
          toogle_left={toogle_left}
          SportsSelect={SportsSelect}
          onSportsSelect={handleSportsSelect}
          onSportsPriceSelect={handleSportsPriceSelect}
          toogle_live={toogle_live}
          onToogle_live={handleClickOpenLive}
          sportData={sportData}
          sportPrice={sportPrice}
          onPageMain={handlePageMain}
          busketBet={busketBet}
          setBusketBet={setBusketBet}
          busketBetPar={busketBetPar}
          setBusketBetPar={setBusketBetPar}
        />
        <div className={"mainstyle"}>
          <Main
            SportsSelect={SportsSelect}
            SportsPriceSelect={SportsPriceSelect}
            toogle_live={toogle_live}
            PageMain={PageMain}
            sportData={sportData}
            sportPrice={sportPrice}
            socketId={socketId}
            onSportsPriceSelect={handleSportsPriceSelect}
            setAtChange={setAtChange}
            atChange={atChange}
            onPageMain={handlePageMain}
            onSportsSelect={handleSportsSelect}
            onToogle_live={handleClickOpenLive}
            setBusketBet={selectBet}
            busketBetPar={busketBetPar}
            setBusketBetPar={selectBetPar}
            setVShow={setVShow}
            vShow={vShow}
          />
        </div>
        <Right />
      </div>
    </>
  );
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}