import React from "react";
import { usePrefixContext } from "../../../context/PrefixContext";


export default function LeagueTitle(props) {
  const { dataLg } = props;
  const prefixContext = usePrefixContext();
  var zone_show = {};
  zone_show["CN"] = dataLg.zone_cn;
  zone_show["EN"] = dataLg.zone_en;
  zone_show["ES"] = dataLg.zone_en;
  zone_show["FR"] = dataLg.zone_en;
  zone_show["IN"] = dataLg.zone_en;
  zone_show["JP"] = dataLg.zone_en;
  zone_show["KH"] = dataLg.zone_en;
  zone_show["KR"] = dataLg.zone_en;
  zone_show["LA"] = dataLg.zone_en;
  zone_show["MM"] = dataLg.zone_en;
  zone_show["PT"] = dataLg.zone_en;
  zone_show["TH"] = dataLg.zone_th;
  zone_show["YN"] = dataLg.zone_en;
  zone_show["RU"] = dataLg.zone_en;
  //var zone_show = (dBall[29]==="" ? dBall[26] : dBall[29]);

  return (
    <tr className="GridItemStyle" align="center" style={{ height: 20 }}>
      <td className="GridHomeStyle" />
      <td
        className="GridHomeStyle"
        align="left"
        colSpan={3}
        style={{ width: 400 }}
      >
        <span>{zone_show[prefixContext.getCurrentLanguage()]}</span>
        <br />
        <span className="GridScore_N"> </span>
      </td>
    </tr>
  );
}
