import React from "react";
import { usePrefixContext } from "../../../context/PrefixContext";
import img_Info from "../../../Images/Info.gif";

import ColumTime from "./ColumTime";
import ColumMatch from "./ColumMatch";
import ColumHDP from "./ColumHDP";
import ColumOU from "./ColumOU";
import Colum1X2 from "./Colum1X2";
import ColumOE from "./ColumOE";

import ColumHDPStep from "./ColumHDPStep";
import ColumOUStep from "./ColumOUStep";
import Colum1X2Step from "./Colum1X2Step";
import ColumOEStep from "./ColumOEStep";
import ColumMLStep from "./ColumMLStep";

import ColumHDPMuay from "./ColumHDPMuay";
import ColumOUMuay from "./ColumOUMuay";

import ColumMLBasketball from "./ColumMLBasketball";
import ColumOEBasketball from "./ColumOEBasketball";

export default function ListBall(props) {
  const {
    liveTB,
    pt,
    atChange,
    bgColor,
    dBall,
    dPrice,
    SportsSelect,
    SportsPriceSelect,
    sortTable,
    teamFev,
    onFavOne,
    setBusketBet, 
    busketBetPar,
    setBusketBetPar,
  } = props;
  /*if(liveTB===1){
    console.log(dBall);
  }*/
  //console.log(dBall[32]);
  /*if(dPrice[0]===7170888){
    console.log(dPrice);
  }*/
  //var b_name1 = (dBall[29]==="" ? dBall[26] : dBall[29]);
  //var b_name2 = (dBall[30]==="" ? dBall[27] : dBall[30]);
  const prefixContext = usePrefixContext();
  var b_name1 = {};
  b_name1["CN"] = dBall[32] === "" ? dBall[26] : dBall[32];
  b_name1["EN"] = dBall[26];
  b_name1["ES"] = dBall[26];
  b_name1["FR"] = dBall[26];
  b_name1["IN"] = dBall[26];
  b_name1["JP"] = dBall[26];
  b_name1["KH"] = dBall[26];
  b_name1["KR"] = dBall[26];
  b_name1["LA"] = dBall[26];
  b_name1["MM"] = dBall[26];
  b_name1["PT"] = dBall[26];
  b_name1["TH"] = dBall[29] === "" ? dBall[26] : dBall[29];
  b_name1["YN"] = dBall[26];
  b_name1["RU"] = dBall[26];

  var b_name2 = {};
  b_name2["CN"] = dBall[33] === "" ? dBall[27] : dBall[33];
  b_name2["EN"] = dBall[27];
  b_name2["ES"] = dBall[27];
  b_name2["FR"] = dBall[27];
  b_name2["IN"] = dBall[27];
  b_name2["JP"] = dBall[27];
  b_name2["KH"] = dBall[27];
  b_name2["KR"] = dBall[27];
  b_name2["LA"] = dBall[27];
  b_name2["MM"] = dBall[27];
  b_name2["PT"] = dBall[27];
  b_name2["TH"] = dBall[30] === "" ? dBall[27] : dBall[30];
  b_name2["YN"] = dBall[27];
  b_name2["RU"] = dBall[27];

  var CFG_StatsPath = "http://stats.betufa.com/Stat";

  return (
    <div
      style={{ display: "table-row", backgroundColor: bgColor }}
      className="GridItem"
    >
      {SportsSelect === 1 ? (
        <>
          <ColumTime
            liveTB={liveTB}
            b_add={dPrice[1]}
            b_live_string={dBall[12]}
            b_date_play={dBall[2]}
            b_run_score_full={dBall[21]}
          />
          <ColumMatch
            liveTB={liveTB}
            pt={pt}
            b_add={dPrice[1]}
            b_id={dBall[0]}
            b_big={dPrice[6]}
            b_hdc={dPrice[7]}
            b_name1={b_name1[prefixContext.getCurrentLanguage()]}
            b_name2={b_name2[prefixContext.getCurrentLanguage()]}
            card_r={dBall[9]}
            SportsSelect={SportsSelect}
            SportsPriceSelect={SportsPriceSelect}
            teamFev={teamFev}
            onFavOne={onFavOne}
          />
          <div style={{ display: "table-cell", width: "65px" }}>
            <div style={{ display: "table" }} className="StrStyleSoc">
              <div style={{ display: "table-row" }}>
                <div
                  style={{
                    display: "table-cell",
                  }}
                >
                  &nbsp;
                </div>
              </div>
              <div style={{ display: "table-row" }}>
                <div
                  style={{
                    display: "table-cell",
                  }}
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <ColumHDPMuay
            b_hf={2}
            dPrice={dPrice}
            atChange={atChange}
            sortTable={sortTable}
            setBusketBet={setBusketBet}
          />
          <ColumOUMuay
            b_hf={2}
            dPrice={dPrice}
            atChange={atChange}
            sortTable={sortTable}
            setBusketBet={setBusketBet}
          />
          <div style={{ display: "table-cell", width: "55px" }}>
            <div style={{ display: "table" }} className="StrStyleSoc">
              <div style={{ display: "table-row" }}>
                <div
                  style={{
                    display: "table-cell",
                  }}
                >
                  &nbsp;
                </div>
              </div>
              <div style={{ display: "table-row" }}>
                <div
                  style={{
                    display: "table-cell",
                  }}
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </>
      ) : SportsSelect === 7 && SportsPriceSelect === 1 ? (
        <>
          <ColumTime
            liveTB={liveTB}
            b_add={dPrice[1]}
            b_live_string={dBall[12]}
            b_date_play={dBall[2]}
            b_run_score_full={dBall[21]}
          />
          <ColumMatch
            liveTB={liveTB}
            pt={pt}
            b_add={dPrice[1]}
            b_id={dBall[0]}
            b_big={dPrice[6]}
            b_hdc={dPrice[7]}
            b_name1={b_name1[prefixContext.getCurrentLanguage()]}
            b_name2={b_name2[prefixContext.getCurrentLanguage()]}
            card_r={dBall[9]}
            SportsSelect={SportsSelect}
            SportsPriceSelect={SportsPriceSelect}
            teamFev={teamFev}
            onFavOne={onFavOne}
          />
          <div
            style={{
              display: "table-cell",
              width: "45px",
              borderWidth: "0px 0px 0px 0px",
              verticalAlign: "middle",
            }}
          >
            <div style={{ width: "1px", border: "none" }}>
              <div
                style={{
                  position: "relative",
                  width: "254px",
                  overflow: "hidden",
                  border: "none",
                }}
              >
                <ColumMLBasketball
                  b_hf={2}
                  atChange={atChange}
                  dPrice={dPrice}
                  SportsSelect={SportsSelect}
                  SportsPriceSelect={SportsPriceSelect}
                  sortTable={sortTable}
                  setBusketBet={setBusketBet}
                />
                <ColumHDPMuay
                  b_hf={2}
                  dPrice={dPrice}
                  atChange={atChange}
                  sortTable={sortTable}
                  setBusketBet={setBusketBet}
                />
                <ColumOUMuay
                  b_hf={2}
                  dPrice={dPrice}
                  atChange={atChange}
                  sortTable={sortTable}
                  setBusketBet={setBusketBet}
                />
                <ColumOEBasketball
                  b_hf={2}
                  atChange={atChange}
                  dPrice={dPrice}
                  SportsSelect={SportsSelect}
                  SportsPriceSelect={SportsPriceSelect}
                  sortTable={sortTable}
                  setBusketBet={setBusketBet}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "table-cell",
              width: "82px",
              borderWidth: "0px 0px 0px 0px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              display: "table-cell",
              width: "82px",
              borderWidth: "0px 0px 0px 0px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              display: "table-cell",
              width: "45px",
              borderWidth: "0px 0px 0px 0px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              display: "table-cell",
              width: "45px",
              borderWidth: "0px 0px 0px 1px",
              verticalAlign: "middle",
            }}
            className="GridItemTD"
          >
            <div style={{ width: "1px", border: "none" }}>
              <div
                style={{
                  position: "relative",
                  width: "254px",
                  overflow: "hidden",
                  border: "none",
                }}
              >
                <ColumMLBasketball
                  b_hf={1}
                  atChange={atChange}
                  dPrice={dPrice}
                  SportsSelect={SportsSelect}
                  SportsPriceSelect={SportsPriceSelect}
                  sortTable={sortTable}
                  setBusketBet={setBusketBet}
                />
                <ColumHDPMuay
                  b_hf={1}
                  dPrice={dPrice}
                  atChange={atChange}
                  sortTable={sortTable}
                  setBusketBet={setBusketBet}
                />
                <ColumOUMuay
                  b_hf={1}
                  dPrice={dPrice}
                  atChange={atChange}
                  sortTable={sortTable}
                  setBusketBet={setBusketBet}
                />
                <ColumOEBasketball
                  b_hf={1}
                  atChange={atChange}
                  dPrice={dPrice}
                  SportsSelect={SportsSelect}
                  SportsPriceSelect={SportsPriceSelect}
                  sortTable={sortTable}
                  setBusketBet={setBusketBet}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "table-cell",
              width: "82px",
              borderWidth: "0px 0px 0px 0px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              display: "table-cell",
              width: "82px",
              borderWidth: "0px 0px 0px 0px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              display: "table-cell",
              width: "45px",
              borderWidth: "0px 0px 0px 0px",
            }}
          >
            &nbsp;
          </div>
        </>
      )
        : SportsSelect === 7 && SportsPriceSelect === 4 ? (
          <>
            <ColumTime
              liveTB={liveTB}
              b_add={dPrice[1]}
              b_live_string={dBall[12]}
              b_date_play={dBall[2]}
              b_run_score_full={dBall[21]}
            />
            <ColumMatch
              liveTB={liveTB}
              pt={pt}
              b_add={dPrice[1]}
              b_id={dBall[0]}
              b_big={dPrice[6]}
              b_hdc={dPrice[7]}
              b_name1={b_name1[prefixContext.getCurrentLanguage()]}
              b_name2={b_name2[prefixContext.getCurrentLanguage()]}
              card_r={dBall[9]}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              teamFev={teamFev}
              onFavOne={onFavOne}
            />
            <div
              style={{
                display: "table-cell",
                width: "45px",
                borderWidth: "0px 0px 0px 0px",
                verticalAlign: "middle",
              }}
            >
              <div style={{ width: "1px", border: "none" }}>
                <div
                  style={{
                    position: "relative",
                    width: "254px",
                    overflow: "hidden",
                    border: "none",
                  }}
                >
                  <ColumMLStep
                    b_hf={2}
                    atChange={atChange}
                    dPrice={dPrice}
                    SportsSelect={SportsSelect}
                    SportsPriceSelect={SportsPriceSelect}
                    sortTable={sortTable}
                    setBusketBetPar={setBusketBetPar}
                  />
                  <ColumHDPStep
                    pt={pt}
                    atChange={atChange}
                    b_hf={2}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    setBusketBetPar={setBusketBetPar}
                  />
                  <ColumOUStep
                    pt={pt}
                    atChange={atChange}
                    b_hf={2}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    setBusketBetPar={setBusketBetPar}
                  />
                  <ColumOEStep
                    b_hf={2}
                    pt={pt}
                    atChange={atChange}
                    dPrice={dPrice}
                    SportsSelect={SportsSelect}
                    SportsPriceSelect={SportsPriceSelect}
                    sortTable={sortTable}
                    setBusketBetPar={setBusketBetPar}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "table-cell",
                width: "82px",
                borderWidth: "0px 0px 0px 0px",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                display: "table-cell",
                width: "82px",
                borderWidth: "0px 0px 0px 0px",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                display: "table-cell",
                width: "45px",
                borderWidth: "0px 0px 0px 0px",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                display: "table-cell",
                width: "45px",
                borderWidth: "0px 0px 0px 1px",
                verticalAlign: "middle",
              }}
              className="GridItemTD"
            >
              <div style={{ width: "1px", border: "none" }}>
                <div
                  style={{
                    position: "relative",
                    width: "254px",
                    overflow: "hidden",
                    border: "none",
                  }}
                >
                  <ColumMLStep
                    b_hf={1}
                    atChange={atChange}
                    dPrice={dPrice}
                    SportsSelect={SportsSelect}
                    SportsPriceSelect={SportsPriceSelect}
                    sortTable={sortTable}
                    setBusketBetPar={setBusketBetPar}
                  />
                  <ColumHDPStep
                    pt={pt}
                    atChange={atChange}
                    b_hf={1}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    setBusketBetPar={setBusketBetPar}
                  />
                  <ColumOUStep
                    pt={pt}
                    atChange={atChange}
                    b_hf={1}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    setBusketBetPar={setBusketBetPar}
                  />
                  <ColumOEStep
                    b_hf={1}
                    pt={pt}
                    atChange={atChange}
                    dPrice={dPrice}
                    SportsSelect={SportsSelect}
                    SportsPriceSelect={SportsPriceSelect}
                    sortTable={sortTable}
                    setBusketBetPar={setBusketBetPar}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "table-cell",
                width: "82px",
                borderWidth: "0px 0px 0px 0px",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                display: "table-cell",
                width: "82px",
                borderWidth: "0px 0px 0px 0px",
              }}
            >
              &nbsp;
            </div>
            <div
              style={{
                display: "table-cell",
                width: "45px",
                borderWidth: "0px 0px 0px 0px",
              }}
            >
              &nbsp;
            </div>
          </>
        ) : SportsPriceSelect === 2 ? (
          <>
            <ColumTime
              liveTB={liveTB}
              b_add={dPrice[1]}
              b_live_string={dBall[12]}
              b_date_play={dBall[2]}
              b_run_score_full={dBall[21]}
            />
            <ColumMatch
              liveTB={liveTB}
              pt={pt}
              b_add={dPrice[1]}
              b_id={dBall[0]}
              b_big={dPrice[6]}
              b_hdc={dPrice[7]}
              b_name1={b_name1[prefixContext.getCurrentLanguage()]}
              b_name2={b_name2[prefixContext.getCurrentLanguage()]}
              card_r={dBall[9]}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              teamFev={teamFev}
              onFavOne={onFavOne}
            />
            <ColumOE
              pt={pt}
              atChange={atChange}
              b_hf={2}
              dPrice={dPrice}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              sortTable={sortTable}
              setBusketBet={setBusketBet}
            />
            <ColumOE
              pt={pt}
              atChange={atChange}
              b_hf={1}
              dPrice={dPrice}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              sortTable={sortTable}
              setBusketBet={setBusketBet}
            />
          </>
        ) : SportsPriceSelect === 3 ? (
          <>
            <ColumTime
              liveTB={liveTB}
              b_add={dPrice[1]}
              b_live_string={dBall[12]}
              b_date_play={dBall[2]}
              b_run_score_full={dBall[21]}
            />
            <ColumMatch
              liveTB={liveTB}
              pt={pt}
              b_add={dPrice[1]}
              b_id={dBall[0]}
              b_big={dPrice[8]}
              b_hdc={dPrice[9]}
              b_name1={b_name1[prefixContext.getCurrentLanguage()]}
              b_name2={b_name2[prefixContext.getCurrentLanguage()]}
              card_r={dBall[9]}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              teamFev={teamFev}
              onFavOne={onFavOne}
            />
            <Colum1X2
              b_hf={2}
              atChange={atChange}
              dPrice={dPrice}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              sortTable={sortTable}
              setBusketBet={setBusketBet}
            />
            <Colum1X2
              b_hf={1}
              atChange={atChange}
              dPrice={dPrice}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              sortTable={sortTable}
              setBusketBet={setBusketBet}
            />
          </>
        ) : SportsPriceSelect === 4 ? (
          <>
            <ColumTime
              liveTB={liveTB}
              b_add={dPrice[1]}
              b_live_string={dBall[12]}
              b_date_play={dBall[2]}
              b_run_score_full={dBall[21]}
            />
            <ColumMatch
              liveTB={liveTB}
              pt={pt}
              b_add={dPrice[1]}
              b_id={dBall[0]}
              b_big={dPrice[6]}
              b_hdc={dPrice[7]}
              b_name1={b_name1[prefixContext.getCurrentLanguage()]}
              b_name2={b_name2[prefixContext.getCurrentLanguage()]}
              card_r={dBall[9]}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              teamFev={teamFev}
              onFavOne={onFavOne}
            />
            <div style={{ display: "table-cell" }}>
              <div style={{ width: "1px" }}>
                <div
                  style={{
                    position: "relative",
                    width: "241px",
                    overflow: "hidden",
                    height: "100%",
                    display: "table",
                  }}
                >
                  <div style={{ display: "table-row" }}>
                    <ColumHDPStep
                      pt={pt}
                      atChange={atChange}
                      b_hf={2}
                      dPrice={dPrice}
                      sortTable={sortTable}
                      busketBetPar={busketBetPar}
                      setBusketBetPar={setBusketBetPar}
                    />
                    <ColumOUStep
                      pt={pt}
                      atChange={atChange}
                      b_hf={2}
                      dPrice={dPrice}
                      sortTable={sortTable}
                      busketBetPar={busketBetPar}
                      setBusketBetPar={setBusketBetPar}
                    />
                    <Colum1X2Step
                      b_hf={2}
                      atChange={atChange}
                      dPrice={dPrice}
                      SportsSelect={SportsSelect}
                      SportsPriceSelect={SportsPriceSelect}
                      sortTable={sortTable}
                      busketBetPar={busketBetPar}
                      setBusketBetPar={setBusketBetPar}
                    />
                    <ColumOEStep
                      b_hf={2}
                      pt={pt}
                      atChange={atChange}
                      dPrice={dPrice}
                      SportsSelect={SportsSelect}
                      SportsPriceSelect={SportsPriceSelect}
                      sortTable={sortTable}
                      busketBetPar={busketBetPar}
                      setBusketBetPar={setBusketBetPar}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div style={{ display: "table-cell" }} className="SEP_R">
              &nbsp;
            </div>
            <div
              style={{ display: "table-cell", borderRight: "none" }}
              className=""
            >
              <div style={{ width: "1px" }}>
                <div
                  style={{
                    position: "relative",
                    width: "241px",
                    overflow: "hidden",
                  }}
                >
                  <ColumHDPStep
                    pt={pt}
                    atChange={atChange}
                    b_hf={1}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    busketBetPar={busketBetPar}
                    setBusketBetPar={setBusketBetPar}
                  />
                  <ColumOUStep
                    pt={pt}
                    atChange={atChange}
                    b_hf={1}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    busketBetPar={busketBetPar}
                    setBusketBetPar={setBusketBetPar}
                  />
                  <Colum1X2Step
                    b_hf={1}
                    atChange={atChange}
                    dPrice={dPrice}
                    SportsSelect={SportsSelect}
                    SportsPriceSelect={SportsPriceSelect}
                    sortTable={sortTable}
                    busketBetPar={busketBetPar}
                    setBusketBetPar={setBusketBetPar}
                  />
                  <ColumOEStep
                    b_hf={1}
                    pt={pt}
                    atChange={atChange}
                    dPrice={dPrice}
                    SportsSelect={SportsSelect}
                    SportsPriceSelect={SportsPriceSelect}
                    sortTable={sortTable}
                    busketBetPar={busketBetPar}
                    setBusketBetPar={setBusketBetPar}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
          </>
        ) : pt === 0 ? (
          <>
            <ColumTime
              liveTB={liveTB}
              b_add={dPrice[1]}
              b_live_string={dBall[12]}
              b_date_play={dBall[2]}
              b_run_score_full={dBall[21]}
            />
            <ColumMatch
              liveTB={liveTB}
              pt={pt}
              b_add={dPrice[1]}
              b_id={dBall[0]}
              b_big={dPrice[6]}
              b_hdc={dPrice[7]}
              b_name1={b_name1[prefixContext.getCurrentLanguage()]}
              b_name2={b_name2[prefixContext.getCurrentLanguage()]}
              card_r={dBall[9]}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              teamFev={teamFev}
              onFavOne={onFavOne}
            />
            <ColumHDP
              pt={pt}
              atChange={atChange}
              b_hf={2}
              dPrice={dPrice}
              sortTable={sortTable}
              setBusketBet={setBusketBet}
            />
            <ColumOU
              pt={pt}
              atChange={atChange}
              b_hf={2}
              dPrice={dPrice}
              sortTable={sortTable}
              setBusketBet={setBusketBet}
            />
            <ColumHDP
              pt={pt}
              atChange={atChange}
              b_hf={1}
              dPrice={dPrice}
              sortTable={sortTable}
              setBusketBet={setBusketBet}
            />
            <ColumOU
              pt={pt}
              atChange={atChange}
              b_hf={1}
              dPrice={dPrice}
              sortTable={sortTable}
              setBusketBet={setBusketBet}
            />
            <div
              style={{
                display: "table-cell",
                verticalAlign: "middle",
                textAlign: "center",
              }}
            >
              {dPrice[1] === 1 ? (
                <img
                  src={img_Info}
                  width="14px"
                  height="14px"
                  border="0"
                  align="absmiddle"
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : pt === 999 ? (
          <>
            <ColumTime
              liveTB={liveTB}
              b_add={dPrice[1]}
              b_live_string={dBall[12]}
              b_date_play={dBall[2]}
              b_run_score_full={dBall[21]}
            />
            <ColumMatch
              liveTB={liveTB}
              pt={pt}
              b_add={dPrice[1]}
              b_id={dBall[0]}
              b_big={dPrice[6]}
              b_hdc={dPrice[7]}
              b_name1={b_name1[prefixContext.getCurrentLanguage()]}
              b_name2={b_name2[prefixContext.getCurrentLanguage()]}
              card_r={dBall[9]}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              teamFev={teamFev}
              onFavOne={onFavOne}
            />
            <div style={{ display: "table-cell" }}>
              <div style={{ width: "1px" }}>
                <div
                  style={{
                    position: "relative",
                    width: (liveTB === 1 ? "204" : "254") + "px",
                    overflow: "hidden",
                    height: "100%",
                    display: "table",
                  }}
                >
                  <div style={{ display: "table-row" }}>
                    <ColumHDP
                      pt={pt}
                      atChange={atChange}
                      b_hf={2}
                      dPrice={dPrice}
                      sortTable={sortTable}
                      setBusketBet={setBusketBet}
                    />
                    <ColumOU
                      pt={pt}
                      atChange={atChange}
                      b_hf={2}
                      dPrice={dPrice}
                      sortTable={sortTable}
                      setBusketBet={setBusketBet}
                    />
                    <Colum1X2
                      b_hf={2}
                      atChange={atChange}
                      dPrice={dPrice}
                      SportsSelect={SportsSelect}
                      SportsPriceSelect={SportsPriceSelect}
                      sortTable={sortTable}
                      setBusketBet={setBusketBet}
                    />
                    {liveTB === 1 ? (
                      ""
                    ) : (
                      <ColumOE
                        pt={pt}
                        b_hf={2}
                        atChange={atChange}
                        dPrice={dPrice}
                        SportsSelect={SportsSelect}
                        SportsPriceSelect={SportsPriceSelect}
                        sortTable={sortTable}
                        setBusketBet={setBusketBet}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            {liveTB === 1 ? (
              ""
            ) : (
              <div style={{ display: "table-cell" }} className="">
                &nbsp;
              </div>
            )}
            <div
              style={{ display: "table-cell", borderRight: "none" }}
              className="GridItemTD"
            >
              <div style={{ width: "1px" }}>
                <div
                  style={{
                    position: "relative",
                    width: "202px",
                    overflow: "hidden",
                    zIndex: 2,
                  }}
                >
                  <ColumHDP
                    pt={pt}
                    atChange={atChange}
                    b_hf={1}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    setBusketBet={setBusketBet}
                  />
                  <ColumOU
                    pt={pt}
                    atChange={atChange}
                    b_hf={1}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    setBusketBet={setBusketBet}
                  />
                  <Colum1X2
                    b_hf={1}
                    atChange={atChange}
                    dPrice={dPrice}
                    SportsSelect={SportsSelect}
                    SportsPriceSelect={SportsPriceSelect}
                    sortTable={sortTable}
                    setBusketBet={setBusketBet}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div
              style={{ display: "table-cell", position: "relative" }}
              className=""
            >
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  height: "100%",
                  width: "20px",
                  borderLeft: "1px solid #A3B4C6",
                  textAlign: "center",
                  display: "table",
                }}
              >
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    {dPrice[1] === 1 && dBall[34] !== "" && dBall[34] !== "0" ? (
                      <img
                        src={img_Info}
                        width="14px"
                        height="14px"
                        border="0"
                        align="absmiddle"
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          window
                            .open(
                              CFG_StatsPath + "/" + dBall[34] + ".html",
                              "Statistic",
                              "width=1000,height=750,top=50,left=100,toolbars=no,scrollbars=yes,status=no,resizable=yes"
                            )
                            .focus()
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : pt === 2 ? (
          ""
        ) : pt === 1 ? (
          <>
            <ColumTime
              liveTB={liveTB}
              b_add={dPrice[1]}
              b_live_string={dBall[12]}
              b_date_play={dBall[2]}
              b_run_score_full={dBall[21]}
            />
            <ColumMatch
              liveTB={liveTB}
              pt={pt}
              b_add={dPrice[1]}
              b_id={dBall[0]}
              b_big={dPrice[6]}
              b_hdc={dPrice[7]}
              b_name1={b_name1[prefixContext.getCurrentLanguage()]}
              b_name2={b_name2[prefixContext.getCurrentLanguage()]}
              card_r={dBall[9]}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              teamFev={teamFev}
              onFavOne={onFavOne}
            />
            <div style={{ display: "table-cell" }}>
              <div style={{ width: "1px" }}>
                <div
                  style={{
                    position: "relative",
                    width: "237px",
                    overflow: "hidden",
                    height: "100%",
                    display: "table",
                  }}
                >
                  <div style={{ display: "table-row" }}>
                    <ColumHDP
                      pt={pt}
                      atChange={atChange}
                      b_hf={2}
                      dPrice={dPrice}
                      sortTable={sortTable}
                      setBusketBet={setBusketBet}
                    />
                    <ColumOU
                      pt={pt}
                      atChange={atChange}
                      b_hf={2}
                      dPrice={dPrice}
                      sortTable={sortTable}
                      setBusketBet={setBusketBet}
                    />
                    <Colum1X2
                      b_hf={2}
                      atChange={atChange}
                      dPrice={dPrice}
                      SportsSelect={SportsSelect}
                      SportsPriceSelect={SportsPriceSelect}
                      sortTable={sortTable}
                      setBusketBet={setBusketBet}
                    />
                    <ColumOE
                      pt={pt}
                      b_hf={2}
                      atChange={atChange}
                      dPrice={dPrice}
                      SportsSelect={SportsSelect}
                      SportsPriceSelect={SportsPriceSelect}
                      sortTable={sortTable}
                      setBusketBet={setBusketBet}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div style={{ display: "table-cell" }} className="SEP_R">
              &nbsp;
            </div>
            <div
              style={{ display: "table-cell", borderRight: "none" }}
              className=""
            >
              <div style={{ width: "1px" }}>
                <div
                  style={{
                    position: "relative",
                    width: "238px",
                    overflow: "hidden",
                    zIndex: 2,
                  }}
                >
                  <ColumHDP
                    pt={pt}
                    atChange={atChange}
                    b_hf={1}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    setBusketBet={setBusketBet}
                  />
                  <ColumOU
                    pt={pt}
                    atChange={atChange}
                    b_hf={1}
                    dPrice={dPrice}
                    sortTable={sortTable}
                    setBusketBet={setBusketBet}
                  />
                  <Colum1X2
                    b_hf={1}
                    atChange={atChange}
                    dPrice={dPrice}
                    SportsSelect={SportsSelect}
                    SportsPriceSelect={SportsPriceSelect}
                    sortTable={sortTable}
                    setBusketBet={setBusketBet}
                  />
                  <ColumOE
                    pt={pt}
                    b_hf={1}
                    atChange={atChange}
                    dPrice={dPrice}
                    SportsSelect={SportsSelect}
                    SportsPriceSelect={SportsPriceSelect}
                    sortTable={sortTable}
                    setBusketBet={setBusketBet}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div style={{ display: "table-cell" }} className="">
              &nbsp;
            </div>
            <div
              style={{ display: "table-cell", position: "relative" }}
              className=""
            >
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  height: "100%",
                  width: "20px",
                  borderLeft: "1px solid #A3B4C6",
                  textAlign: "center",
                  display: "table",
                }}
              >
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    {dPrice[1] === 1 && dBall[34] !== "" && dBall[34] !== "0" ? (
                      <img
                        src={img_Info}
                        width="14px"
                        height="14px"
                        border="0"
                        align="absmiddle"
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          window
                            .open(
                              CFG_StatsPath + "/" + dBall[34] + ".html",
                              "Statistic",
                              "width=1000,height=750,top=50,left=100,toolbars=no,scrollbars=yes,status=no,resizable=yes"
                            )
                            .focus()
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
    </div>
  );
}
