import React from "react";
import { useTranslation } from 'react-i18next';

export default function TitleBall2Row() {
  const { t } = useTranslation();
  return (
    <div style={{ display: "table-row" }} className="GridHeader">
          <div style={{ display: "table-cell", width: "45px" }} className="table_th3">{t("time")}</div>
          <div style={{ display: "table-cell", width: "231px" }} className="table_th4">{t("event")}</div>
          <div style={{ display: "table-cell" , width: "76px"}}>
            <div style={{ width: "1px", border:"none" }}>
            <div style={{ position: "relative", width: "498px", overflow: "hidden", border:"none" }}>
            <div style={{ display: "table" , borderWidth:"0px 0px 0px 0px" }}>
              <div style={{ display: "table-row", borderWidth:"0px 0px 0px 0px" }} className="GridHeader">
                <div style={{ display: "table-cell" , width: "76px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">
                  <div style={{ width: "1px", border:"none" }}>
                    <div style={{ position: "relative", width: "239px", overflow: "hidden", border:"none" }}>{t("full_Time")}</div>
                  </div>
                </div>
                <div style={{ display: "table-cell" , width: "76px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "42px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "45px" , borderWidth:"0px 1px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "76px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">
                  <div style={{ width: "1px", border:"none" }}>
                    <div style={{ position: "relative", width: "259px", overflow: "hidden", border:"none" }}>{t("first_Half")}</div>
                  </div>
                </div>
                <div style={{ display: "table-cell" , width: "76px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "42px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "65px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
              </div>
              <div style={{ display: "table-row", borderWidth:"0px 0px 0px 0px" }} className="GridHeader">
                <div style={{ display: "table-cell" , width: "76px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("hdp")}</div>
                <div style={{ display: "table-cell" , width: "76px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("O_U")}</div>
                <div style={{ display: "table-cell" , width: "42px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">1X2</div>
                <div style={{ display: "table-cell" , width: "45px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("oe_title")}</div>
                <div style={{ display: "table-cell" , width: "76px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("hdp")}</div>
                <div style={{ display: "table-cell" , width: "76px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("O_U")}</div>
                <div style={{ display: "table-cell" , width: "42px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">1X2</div>
                <div style={{ display: "table-cell" , width: "65px", borderWidth:"0px 0px 0px 0px" }} className="table_th1">{t("oe_title")}</div>
              </div>
            </div>
            </div>
            </div>
          </div>
          <div style={{ display: "table-cell" , width: "76px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "42px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "45px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "76px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "76px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "42px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "65px" }}>&nbsp;</div>
      </div>
  );
}
