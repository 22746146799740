import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import api from './../../../js/api';

import { useAuthContext } from "../../../context/AuthContext";

import { usePrefixContext } from "../../../context/PrefixContext";

import { Link } from "react-router-dom";
import OddsSel from "../../OddsSel";

import img_icoleft from "../../../Images/icoleft.png";

export default function BarOption(props) {
  const {
    onOpenSelectLeague,
    onPt,
    atChange,
    setAtChange,
    onSortTable,
    pt,
    sortTable,
    liveTB,
    toogle_live,
    SportsSelect,
    SportsPriceSelect,
    socketId,
    onSportsPriceSelect,
    thisFavPage,
    setBusketBet,
    setBusketBetPar,
  } = props;

  const btnSelectLeagueRef = useRef();
  const { prefix } = usePrefixContext();
  const { t } = useTranslation();

  const { activeUser , setActUser } = useAuthContext();

  //console.log(activeUser);

  const handleOpenSelectLeague = (value) => {
    const { offsetTop, offsetHeight } = btnSelectLeagueRef.current;
    const etop = offsetTop + offsetHeight + 1;
    onOpenSelectLeague(value, etop);
  };

  const handlePt = (value) => {
    onPt(value);
  };

  const handleAt = async (value) => {
    try {
      if(value!==activeUser.m_at){
        setAtChange(true);
        setBusketBet("clear");
        setBusketBetPar("clear");
        const res = await api.post(`setAT`, {"val":value , "scid":socketId});
        setActUser(res.data);
      }
      //await saveAuthenticationToken(res.data.token, res.data.refresh_token, true);
      //return res.data;
    } catch (error) {
       alert("Error");
    }
  };

  const handleSortTable = () => {
    //console.log("sssss");
    onSortTable();
  };

  const handleBallSTEP = () => {
    //console.log("sssss");
    onSportsPriceSelect(4);
  };

  return (
    <div className="tbBarOption">
      <div style={{ display: "table-row" }}>
        <div
          style={{
            display: "table-cell",
            width: "20px",
            textAlign: "left",
            verticalAlign: "middle",
          }}
        >
          <img
            src={img_icoleft}
            width="15"
            height="15"
            align="absmiddle"
            alt=""
          />
        </div>
        <div
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            width: "755px",
          }}
        >
          <div
            className="optCont"
            style={{
              height: "25px",
              width: "100%",
            }}
          >
            <div style={{ display: "table-row" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                }}
              >
                <span className="GridTitle">
                  {liveTB === 1
                    ? SportsPriceSelect !== 1 || SportsSelect !==6
                    ? t(prefix.sport_list[SportsSelect]) +
                      " - " +
                      (SportsSelect !==6 && SportsPriceSelect !== 4 ? "ทุกตัว" : t(prefix.sport_price[SportsPriceSelect]))
                    : t("running")
                    : SportsPriceSelect !== 1 || SportsSelect !==6
                    ? t(prefix.sport_list[SportsSelect]) +
                      " - " +
                      (SportsSelect !==6 && SportsPriceSelect !== 4 ? "ทุกตัว" : t(prefix.sport_price[SportsPriceSelect]))
                    : t("today_events")}
                </span>
              </div>
              {SportsSelect === 6 && thisFavPage === 0 ? (
                <div
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    textAlign: "right",
                  }}
                >
                  <div style={{ float: "right" }}>
                    {toogle_live === false && SportsPriceSelect === 1 ? (
                      <div
                        style={{
                          float: "left",
                          backgroundColor: "#745A29",
                          borderRadius: "3px",
                          height: "19px",
                          padding: "0px 15px",
                        }}
                      >
                        <Link to="/" className="btnStep" onClick={handleBallSTEP}>
                          {t("step")}
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                    {SportsPriceSelect !== 2 ? (
                      <div style={{ float: "left" }}>
                        <Link
                          className="btnGrey"
                          to="/"
                          onClick={handleSortTable}
                        >
                          <span>
                            {sortTable === 0
                              ? t("sort_by_time")
                              : t("sort_by_league")}
                          </span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}

                    {SportsPriceSelect === 1 ? (
                      <div style={{ float: "left" }}>
                        <Link
                          className="btnGrey btnSelectLeague"
                          ref={btnSelectLeagueRef}
                          to="/"
                          onClick={() => handleOpenSelectLeague("block")}
                        >
                          <span>{t("select_league")}</span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}

                    {SportsPriceSelect === 1 ? (
                      <OddsSel
                        width={90}
                        disable={false}
                        toggleOddsSel={"divPT_T"}
                        onPA={handlePt}
                        pa={pt}
                        arrPA={{ 0: t("single"), 1: t("double") }}
                      />
                    ) : (
                      ""
                    )}

                    {SportsPriceSelect === 1 ? (
                      <OddsSel
                        width={50}
                        disable={atChange}
                        toggleOddsSel={"divAT_T"}
                        onPA={handleAt}
                        pa={activeUser.m_at}
                        arrPA={{
                          MY: t("my"),
                          HK: t("hk"),
                          EU: t("eu"),
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/*<div
          style={{
            display: "table-cell",
            width: "10px",
          }}
        >
          &nbsp;
        </div>*/}
      </div>
    </div>
  );
}
