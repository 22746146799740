import React, { useState, useEffect } from "react";
import tabHIcon from "../../Images/tabHIcon.png";
import { Link } from "react-router-dom";
import { usePrefixContext } from "../../context/PrefixContext";
import { useTranslation } from "react-i18next";

import api from "../../js/api";

import RowGTB from "./tableResult/RowGTB";

import LoadSkeletonResult from "../LoadSkeletonResult";



export default function Result() {
  const { prefix , getCurrentLanguage } = usePrefixContext();
  const [lstSortBy, setlstSortBy] = useState(0);
  const [lstDates, setlstDates] = useState("");
  const [listDate, setlistDate] = useState([]);
  const [lstGameType, setlstGameType] = useState(6);
  const [sportData, setSportData] = useState("");
  const [lstEvent, setlstEvent] = useState(-1);
  const [isCompleted, setIsCompleted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    //const controller = AbortController();
    
    //console.log(lstDates);
    async function fetchData() {
      try {
        setIsCompleted(false);
        setlstEvent(-1);
        var spData = {};
        const res = await api.post(`getResult`, {
          lstDates: lstDates,
          lstGameType: lstGameType,
        });
        if (res.data.status === 1) {
          spData = res.data.data;
          setSportData(JSON.stringify(spData));
        }
        setIsCompleted(true);
      } catch (error) {
        setIsCompleted(true);
      }
    }
    if (lstDates === "") {
      var time_stam = parseInt(Date.now() / 1000);

      var date_data = new Date(time_stam * 1000);
      var firstdate = new Date(time_stam * 1000);
      var hours_data = date_data.getHours();

      //console.log(hours_data);

      if (hours_data < 11) {
        firstdate.setDate(date_data.getDate() - 1);
      }

      //console.log(firstdate);

      var listDatex = [];
      for (var i = 0; i < 7; i++) {
        //var usedate = new Date();
        firstdate.setDate(firstdate.getDate() - (i>0 ? 1 : 0));
        var d_data = "0" + firstdate.getDate();
        var m_data = "0" + (firstdate.getMonth()+1);
        var y_data = firstdate.getFullYear();

        var view_date = d_data.substr(-2) + "/" + m_data.substr(-2) + "/" + y_data;
        listDatex.push(view_date);
        //console.log(view_date);
      }

      

      setlistDate(listDatex);
      setlstDates(listDatex[0]);
    }else{
      fetchData();
    }
    
    return () => {};
  }, [lstDates, lstGameType]);

  const changelstSortBy = (val) => {
    setlstSortBy(parseInt(val));
    setlstEvent(-1);
  };
  const changelstDates = (val) => {
    setlstDates(val);
  };
  const changelstGameType = (val) => {
    setlstGameType(parseInt(val));
  };
  const changelstEvent = (val) => {
    setlstEvent(parseInt(val));
  };

  var array_sport_list = Object.keys(prefix["sport_list"]).map(function (key) {
    var obj_r = { k: key, v: prefix["sport_list"][key] };
    return obj_r;
  });

  var zbData = {};
  if (sportData !== "") {
    zbData = JSON.parse(sportData);
  }

  var zone_all = [];
  var zone_show = [];

  var zone_show_data = {};

  for (var iy in zbData) {
    var spx = zbData[iy];

    //console.log(spx);
    zone_all.push({
      zone_key: spx[15],
      zone_id: spx[15],
      zone_en: spx[25],
      zone_th: spx[28],
      zone_cn: spx[31],
      zASC: spx[2],
    });

    if (lstEvent !== -1 && lstEvent !== spx[15]) {
      continue;
    }

    if (lstSortBy === 1) {
      if (zone_show_data[spx[15] + "_" + spx[2]] === undefined) {
        zone_show_data[spx[15] + "_" + spx[2]] = [];
      }
      zone_show_data[spx[15] + "_" + spx[2]].push(spx);
      zone_show.push({
        zone_key: spx[15] + "_" + spx[2],
        zone_id: spx[15],
        zone_en: spx[25],
        zone_th: spx[28],
        zone_cn: spx[31],
        zASC: spx[2],
      });
    } else {
      if (zone_show_data[spx[15]] === undefined) {
        zone_show_data[spx[15]] = [];
      }
      zone_show_data[spx[15]].push(spx);
      zone_show.push({
        zone_key: spx[15],
        zone_id: spx[15],
        zone_en: spx[25],
        zone_th: spx[28],
        zone_cn: spx[31],
        zASC: spx[23],
      });
    }
  }

  zone_all = RemoveDuplicates(zone_all, "zone_key");
  zone_all.sort(dynamicSortMultiple("zone_en Asc", "zone_id Asc"));

  zone_show = RemoveDuplicates(zone_show, "zone_key");
  zone_show.sort(dynamicSortMultiple("zASC Asc", "zone_id Asc"));

  if (lstSortBy === 1) {
    var oldzx_show = 0;
    var newzxd_show = [];

    for (var zzo in zone_show) {
      var zzod = zone_show[zzo];
      if (oldzx_show !== zzod["zone_id"]) {
        oldzx_show = zzod["zone_id"];
        newzxd_show.push(zzod);
      } else {
        var zkeyx = zone_show[zzo - 1]["zone_key"];
        if (zone_show_data[zkeyx] !== undefined) {
          var zkeyo = zzod["zone_key"];
          for (var zzddx in zone_show_data[zkeyo]) {
            zone_show_data[zkeyx].push(zone_show_data[zkeyo][zzddx]);
          }
          delete zone_show_data[zkeyo];
        }
      }
    }
    zone_show = newzxd_show;
  }

  return (
    <div
      style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }}
      className="Backgroundx "
    >
      <div style={{ height: "12px" }}></div>
      <div className="preferenceTB">
        <div></div>
        <div style={{ width: "775px" }} className="tabGridBg1">
          <table
            width="100%"
            height="100%"
            border="0"
            cellSpacing="1"
            cellPadding="0"
          >
            <tbody>
              <tr className="tabHeaderBg">
                <td>
                  <table
                    width="100%"
                    height="100%"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "10px" }}></td>
                        <td style={{ width: "25px" }} align="left">
                          <img alt="" src={tabHIcon} />
                        </td>
                        <td align="left" className="GridHeaderx">
                          {t("Results")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="tabGridBg2">
                  <table
                    width="100%"
                    height="100%"
                    border="0"
                    cellSpacing="5"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr>
                        <td className="tabGridBg3">
                          <table
                            width="100%"
                            height="100%"
                            border="0"
                            cellSpacing="5"
                            cellPadding="0"
                          >
                            <tbody>
                              <tr>
                                <td className="tabGridBg4">
                                  <table
                                    width="100%"
                                    height="100%"
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                    className="GridTable"
                                  >
                                    <tbody>
                                      <tr className="ResBg">
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          &nbsp;&nbsp;{t("Sort_By")}&nbsp;:&nbsp;{" "}
                                          <span style={{ width: 80 }}>
                                            <select
                                              className="ddlLang"
                                              value={lstSortBy}
                                              disabled={!isCompleted}
                                              onChange={(event) =>
                                                changelstSortBy(
                                                  event.target.value
                                                )
                                              }
                                            >
                                              <option value={0}>{t("League")}</option>
                                              <option value={1}>{t("time")}</option>
                                            </select>
                                          </span>
                                          &nbsp;&nbsp;{t("Date")} :&nbsp;{" "}
                                          <span style={{ width: 90 }}>
                                            <select
                                              className="ddlLang"
                                              value={lstDates}
                                              disabled={!isCompleted}
                                              onChange={(event) =>
                                                changelstDates(
                                                  event.target.value
                                                )
                                              }
                                            >
                                              {listDate.map((i, l) => {
                                                return (
                                                  <option key={l} value={i}>
                                                    {i}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </span>
                                          <Link
                                            className="btnGrey"
                                            to="/"
                                            disabled={!isCompleted}
                                            onClick={() =>
                                              changelstDates(listDate[0])
                                            }
                                          >
                                            <span> {t("today")} </span>
                                          </Link>
                                          <Link
                                            className="btnGrey"
                                            to="/"
                                            disabled={!isCompleted}
                                            onClick={() =>
                                              changelstDates(listDate[1])
                                            }
                                          >
                                            <span> {t("Yesterday")} </span>
                                          </Link>
                                          <div style={{ height: 5 }} />{" "}
                                          &nbsp;&nbsp;เลือก&nbsp;:&nbsp;{" "}
                                          <select
                                            className="ddlLang"
                                            style={{ width: 200 }}
                                            disabled={!isCompleted}
                                            value={lstGameType}
                                            onChange={(event) =>
                                              changelstGameType(
                                                event.target.value
                                              )
                                            }
                                          >
                                            {array_sport_list.map((i, l) => {
                                              if (
                                                i.k !== "1" &&
                                                i.k !== "6" &&
                                                i.k !== "7"
                                              ) {
                                                return false;
                                              }
                                              return (
                                                <option key={l} value={i.k}>
                                                  {t(i.v)}
                                                </option>
                                              );
                                            })}
                                          </select>
                                          &nbsp;
                                          <select
                                            className="ddlLang"
                                            style={{ width: 360 }}
                                            disabled={!isCompleted}
                                            value={lstEvent}
                                            onChange={(event) =>
                                              changelstEvent(event.target.value)
                                            }
                                          >
                                            <option value={-1}>{t("All")}</option>
                                            {zone_all.map((i, l) => {
                                              var zone_showx = {};
                                              zone_showx["CN"] = i.zone_cn;
                                              zone_showx["EN"] = i.zone_en;
                                              zone_showx["ES"] = i.zone_en;
                                              zone_showx["FR"] = i.zone_en;
                                              zone_showx["IN"] = i.zone_en;
                                              zone_showx["JP"] = i.zone_en;
                                              zone_showx["KH"] = i.zone_en;
                                              zone_showx["KR"] = i.zone_en;
                                              zone_showx["LA"] = i.zone_en;
                                              zone_showx["MM"] = i.zone_en;
                                              zone_showx["PT"] = i.zone_en;
                                              zone_showx["TH"] = i.zone_th;
                                              zone_showx["YN"] = i.zone_en;
                                              zone_showx["RU"] = i.zone_en;
                                              
                                              return (
                                                <option
                                                  key={l}
                                                  value={i.zone_id}
                                                >
                                                  {zone_showx[getCurrentLanguage()]}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {isCompleted === true ? (
                                            <table
                                              className="GridStyle"
                                              cellSpacing={1}
                                              cellPadding={3}
                                              rules="all"
                                              id="g1"
                                              style={{
                                                borderWidth: 0,
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  className="GridHeaderStyle"
                                                  align="center"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  <td
                                                    className="GridColor"
                                                    style={{ width: 130 }}
                                                  >
                                                    {t("Kickoff_Time")}
                                                  </td>
                                                  <td
                                                    className="GridColor"
                                                    align="left"
                                                  >
                                                    &nbsp;&nbsp; {t("Match")}
                                                  </td>
                                                  <td
                                                    className="GridColor"
                                                    align="center"
                                                    style={{ width: 130 }}
                                                  >
                                                    {t("First_Half_Score")}
                                                  </td>
                                                  <td
                                                    className="GridColor"
                                                    align="center"
                                                    style={{ width: 130 }}
                                                  >
                                                    {t("Full_Time_Score")}
                                                  </td>
                                                </tr>
                                                {zone_show.map(function (
                                                  tlist,
                                                  j
                                                ) {
                                                  return (
                                                    <RowGTB
                                                      key={j}
                                                      dataLg={tlist}
                                                      dataSp={
                                                        zone_show_data[
                                                          tlist.zone_key
                                                        ]
                                                      }
                                                    />
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          ) : (
                                            <LoadSkeletonResult />
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
function dynamicSortMultiple() {
  var props = [];
  /*Let's separate property name from ascendant or descendant keyword*/
  for (var i = 0; i < arguments.length; i++) {
    var splittedArg = arguments[i].split(/ +/);
    props[props.length] = [
      splittedArg[0],
      splittedArg[1] ? splittedArg[1].toUpperCase() : "ASC",
    ];
  }
  return function (obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length;
    /*Cycle on values until find a difference!*/
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i][0], props[i][1])(obj1, obj2);
      i++;
    }
    return result;
  };
}

function dynamicSort(property, isAscDesc) {
  return function (obj1, obj2) {
    if (isAscDesc === "DESC") {
      return obj1[property] > obj2[property]
        ? -1
        : obj1[property] < obj2[property]
        ? 1
        : 0;
    }
    /*else, if isAscDesc==="ASC"*/
    return obj1[property] > obj2[property]
      ? 1
      : obj1[property] < obj2[property]
      ? -1
      : 0;
  };
}
const RemoveDuplicates = (array, key) => {
  return array.reduce((arr, item) => {
    const removed = arr.filter((i) => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};
