import React , { useState , useEffect , useRef } from "react";
import { usePrefixContext } from "../context/PrefixContext";
import { useAuthContext } from "../context/AuthContext";
import IntlCurrencyInput from "react-intl-currency-input"
import ClipLoader from 'react-spinners/ClipLoader';

import apiBet from "../js/apiBet";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const currencyConfig = {
  locale: "th-TH",
  formats: {
    number: {
      THB: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
  },
};

var zbBlink = 0;
var showBlink = 0;
export default function BetBox(props) {
  const { busketBet , setBusketBet , sportData, sportPrice , setViewBet , setToogle_menu } = props;
  const prefixContext = usePrefixContext();
  const { activeUser , setActUser } = useAuthContext();
  const [currentAcBetter, setCurrentAcBetter] = useState(activeUser.m_ac_better);
  const [currentMinBet, setCurrentMinBet] = useState(activeUser.m_min_bet);
  //const [currentMaxBet, setCurrentMaxBet] = useState(activeUser.m_max);
  const [inputxBet, setInputBet] = useState(0);
  const prevOdds = usePrevious(sportPrice);
  const [loadingInProgress, setLoading] = useState(false);

  useEffect(() => {
    setCurrentAcBetter(activeUser.m_ac_better);
    setCurrentMinBet(activeUser.m_min_bet);
    //setCurrentMaxBet(activeUser.m_max);
    setInputBet(0);
    const inputElement = document.querySelector('.BetTextBox');

    if (inputElement) {
      inputElement.focus();
    }
    return () => {
      setInputBet(0);
    };
  }, [busketBet , activeUser]);



  const closeBet = () => {
    setBusketBet();
  };

  const changeAcBetter = () => {
    if(currentAcBetter===1){
      setCurrentAcBetter(0);
    }else{
      setCurrentAcBetter(1);
    }
    
  }

  

  if(sportData.length<=0){
    return null;
  }

  var dBall = sportData;

  var zone_show = {};
  zone_show["CN"] = (dBall[31]==="" ? dBall[25] : dBall[31]);
  zone_show["EN"] = dBall[25];
  zone_show["ES"] = dBall[25];
  zone_show["FR"] = dBall[25];
  zone_show["IN"] = dBall[25];
  zone_show["JP"] = dBall[25];
  zone_show["KH"] = dBall[25];
  zone_show["KR"] = dBall[25];
  zone_show["LA"] = dBall[25];
  zone_show["MM"] = dBall[25];
  zone_show["PT"] = dBall[25];
  zone_show["TH"] = (dBall[28]==="" ? dBall[25] : dBall[28]);
  zone_show["YN"] = dBall[25];
  zone_show["RU"] = dBall[25];

  var b_name1 = {};
  b_name1["CN"] = (dBall[32]==="" ? dBall[26] : dBall[32]);
  b_name1["EN"] = dBall[26];
  b_name1["ES"] = dBall[26];
  b_name1["FR"] = dBall[26];
  b_name1["IN"] = dBall[26];
  b_name1["JP"] = dBall[26];
  b_name1["KH"] = dBall[26];
  b_name1["KR"] = dBall[26];
  b_name1["LA"] = dBall[26];
  b_name1["MM"] = dBall[26];
  b_name1["PT"] = dBall[26];
  b_name1["TH"] = (dBall[29]==="" ? dBall[26] : dBall[29]);
  b_name1["YN"] = dBall[26];
  b_name1["RU"] = dBall[26];

  var b_name2 = {};
  b_name2["CN"] = (dBall[33]==="" ? dBall[27] : dBall[33]);
  b_name2["EN"] = dBall[27];
  b_name2["ES"] = dBall[27];
  b_name2["FR"] = dBall[27];
  b_name2["IN"] = dBall[27];
  b_name2["JP"] = dBall[27];
  b_name2["KH"] = dBall[27];
  b_name2["KR"] = dBall[27];
  b_name2["LA"] = dBall[27];
  b_name2["MM"] = dBall[27];
  b_name2["PT"] = dBall[27];
  b_name2["TH"] = (dBall[30]==="" ? dBall[27] : dBall[30]);
  b_name2["YN"] = dBall[27];
  b_name2["RU"] = dBall[27];

  if(Object.keys(sportPrice).length<=0){
    return null;
  }

  var title_bet = sportPrice["title_bet"];
  var type_bet = sportPrice["type_bet"];
  var BetOdds = sportPrice["BetOdds"];
  var IsHomeGive = sportPrice["IsHomeGive"];
  var BetHdp = sportPrice["BetHdp"];
  var BetPlay = sportPrice["BetPlay"];
  var BetPlayClass = sportPrice["BetPlayClass"];

  //const prevBall = usePrevious(BetOdds);
  //console.log(prevBall);

  //console.log(busketBet);

  if(prevOdds!==undefined){
    if((prevOdds.id_type===sportPrice.id_type) && (prevOdds.BetType===sportPrice.BetType)){
      if(prevOdds.BetOdds!==BetOdds){
        zbBlink = new Date();
      }else{
        if(zbBlink!==0){
          var now_t = new Date();
          var d_time = getMinutesBetweenDates(zbBlink, now_t);
          if(d_time<7){
            showBlink = 1;
          }else{
            showBlink = 0;
          }
        }
      }
    }else{
      zbBlink = 0;
      showBlink = 0;
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Call your function here
      saveBusket();
      // You can replace this with the desired function
    }
  };

  const handleChange = (event, value) => {
    event.preventDefault();
    setInputBet(value);
  };

  var currentMaxBet = (activeUser.m_max_bet===undefined ? 0 : activeUser.m_max_bet);
  
  var max_pay = 0;

  if(activeUser.m_at==="EU" || busketBet.id_type_num===3 || busketBet.id_type_num===7){
    max_pay = (inputxBet*(BetOdds-1));
    var over_pay1 = (parseFloat(activeUser.m_max_bet)*(BetOdds-1));
    if(over_pay1>parseFloat(activeUser.m_paymax)){
      currentMaxBet = Math.floor((parseFloat(activeUser.m_paymax)/(BetOdds-1)));
    }
  }else{
    if(BetOdds<0){
      max_pay = inputxBet;
      var over_pay2 = parseFloat(activeUser.m_max_bet);
      if(over_pay2>parseFloat(activeUser.m_paymax)){
        currentMaxBet = parseFloat(activeUser.m_paymax);
      }
    }else{
      max_pay = (inputxBet*BetOdds);
      var over_pay3 = (parseFloat(activeUser.m_max_bet)*BetOdds);
      if(over_pay3>parseFloat(activeUser.m_paymax)){
        currentMaxBet = Math.floor((parseFloat(activeUser.m_paymax)/(BetOdds)));
      }
    }
  }



  const saveBusket = async () => {
    try {
      if (window.confirm("เดิมพันยืนยัน?") === true) {
        if(inputxBet<currentMinBet || currentMinBet===undefined){
          alert("ยอดเดิมพันต่ำกว่าจำนวนที่กำหนด");
          return false;
        }

        if(inputxBet>currentMaxBet){
          alert("ยอดเดิมพันสูงกว่าจำนวนที่กำหนด");
          return false;
        }
        
        var $play_my_loss = inputxBet;
        if(activeUser.m_at==="MY" || activeUser.m_at==="ID") {
          if(BetOdds < 0.0) {
            var $play_my_loss = inputxBet * -BetOdds;
          }
        }

        if($play_my_loss>parseFloat(activeUser.m_count)){
          alert("เครดิตไม่เพียงพอ");
          return false;
        }
        
        //console.log(inputxBet , parseFloat(activeUser.m_count));
        //const token = localStorage.getItem("a-token");
        //api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        //console.log(token);
        setLoading(true);
        const res = await apiBet.post(`PanelBet`, {"amt":inputxBet , "isBetterOdds":currentAcBetter , "betData":sportPrice , "BPBetKey":busketBet.id_type+"_"+busketBet.BetType , "socOddsId":busketBet.BetMatch});

        if(res.data.status===1){
          var old_act = activeUser;
          old_act["m_count"] = res.data.m_count;
          alert(res.data.data);
          setBusketBet();
          setToogle_menu(false);
          setActUser(old_act);
          setViewBet(res.data.w);
          setLoading(false);
        }else{
          alert(res.data.message);
          setLoading(false);
        }

        //console.log(res);
        
      }
      //alert(inputRef.current.value); 
    } catch (error) {
       alert("Error");
       setLoading(false);
    }
  };

  
  return (
    <div className="tbBetBox" style={{display:(Object.keys(busketBet).length === 0 ? "none" : "") , width:"180px" , margin:"auto" , position:"relative"}}>
      <div className={dBall[10] > 0 ? "PanelBetBoxBgRun" : "PanelBetBoxBgToday"}>
        <table
          width="174px"
          border={0}
          cellPadding={0}
          cellSpacing={0}
          className="BetBoxGrid"
          style={{ margin: "auto" }}
        >
          <tbody>
            <tr>
              <td align="center">
                <table
                  width="164px"
                  border={0}
                  cellPadding={0}
                  cellSpacing={0}
                  className="BetBoxGrid2"
                >
                  <tbody>
                    <tr className="BetBoxBg" style={{ height: 25 }}>
                      <td align="center">
                        <table
                          width="164px"
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                        >
                          <tbody>
                            <tr>
                              <td align="left" className="txtBetBold">
                                <span className="GPanTitle">
                                  <label id="betGTitle">
                                    {title_bet}
                                  </label>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr id="trPanNonParlay" style={{}} className="BetBoxBg3">
                      <td align="center">
                        <table
                          width="164px"
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <table
                                  width="164px"
                                  align="center"
                                  border={0}
                                  cellPadding={2}
                                  cellSpacing={0}
                                  className={"BetBoxGrid3"+(showBlink===1 ? " NewOdds2":"")}
                                >
                                  <tbody>
                                    <tr style={{ height: 5 }}>
                                      <td />
                                    </tr>
                                    <tr align="center">
                                      <td className="txtBet">
                                        <label id="socBetTeam">
                                          <span
                                            className={BetPlayClass}
                                            style={{ fontSize: 13 }}
                                          >
                                            {BetPlay}
                                          </span>
                                        </label>
                                        <br />
                                        <span
                                          style={{
                                            fontSize: 11,
                                            fontWeight: "bold",
                                            color: "#606060",
                                          }}
                                        >
                                          <label id="socBetHdp">
                                            {type_bet}&nbsp;&nbsp;{((IsHomeGive === true && busketBet.BetType === "home" && BetHdp !== "0") || (IsHomeGive === false && busketBet.BetType === "away" && BetHdp !== "0" && BetHdp !== "") ? "-" : "")+BetHdp}
                                          </label>
                                        </span>
                                        <label id="socBetOdds">
                                          &nbsp;@&nbsp;
                                          {BetHdp !== "" ? <span className={BetOdds < 0 ? "txtNegOdds" : "txtPosOdds"}>
                                            {formatMoney(BetOdds, 2)}
                                          </span> : busketBet.id_type_num === 4 || busketBet.id_type_num === 8 ? <span className={"txtBetBlueBold"}>
                                            {formatMoney(BetOdds, 2)}
                                          </span> : formatMoney(BetOdds, 2)}
                                        </label>
                                      </td>
                                    </tr>
                                    <tr style={{ height: 5 }}>
                                      <td />
                                    </tr>
                                    <tr>
                                      <td>
                                        <span
                                          style={{
                                            fontSize: 10,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <label id="socRemark" />
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr style={{ height: 5 }}>
                              <td />
                            </tr>
                            <tr>
                              <td>
                                <table
                                  width="164px"
                                  align="center"
                                  border={0}
                                  cellPadding={2}
                                  cellSpacing={0}
                                  className="txtBet"
                                >
                                  <tbody>
                                    <tr id="trPanMatch" style={{}}>
                                      <td>
                                        <table
                                          border={0}
                                          cellPadding={2}
                                          cellSpacing={0}
                                          width="100%"
                                          className="txtBet"
                                        >
                                          <tbody>
                                            <tr align="center">
                                              <td>
                                                <label id="socFullTimeId">{busketBet.BetHF === 1 ? "ครึ่งแรก" : ""}</label>
                                              </td>
                                            </tr>
                                            <tr align="center">
                                              <td
                                                id="socClsHome"
                                                className="txtBetBlue"
                                              >
                                                <label id="socHome">
                                                  {b_name1[prefixContext.getCurrentLanguage()]}
                                                </label>
                                              </td>
                                            </tr>
                                            <tr align="center">
                                              <td>-vs-</td>
                                            </tr>
                                            <tr align="center">
                                              <td
                                                id="socClsAway"
                                                className="txtBetRed"
                                              >
                                                <label id="socAway">
                                                {b_name2[prefixContext.getCurrentLanguage()]}
                                                </label>
                                              </td>
                                            </tr>
                                            <tr align="center">
                                              <td>
                                                <label id="socIsRun">
                                                  {dBall[10] > 0 ? "( สกอร์: "+dBall[21].replace("-", " - ")+" )" : ""}
                                                </label>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr align="center">
                                      <td className="txtModuleTitle">
                                        <label id="socModuleTitle">
                                        {zone_show[prefixContext.getCurrentLanguage()]}
                                        </label>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr style={{ height: 5 }}>
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className="BetBoxBg" style={{ height: 25 }}>
                      <td align="center">
                        <table
                          width="164px"
                          border={0}
                          cellPadding={0}
                          cellSpacing={2}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <span className="txtBet">จำนวนเงิน: </span>
                                <IntlCurrencyInput className="BetTextBox" style={{
                                    width: 100,
                                    height: 20,
                                    boxSizing: " border-box",
                                  }} 
                                  currency="THB" 
                                  autoFocus={true}
                                  config={currencyConfig} 
                                  max={Number(currentMaxBet)}
                                  value={(inputxBet>currentMaxBet ? currentMaxBet : inputxBet)}
                                  onChange={handleChange}
                                  onKeyPress={handleKeyPress} />
                              </td>
                            </tr>
                            <tr style={{ height: 5 }}>
                              <td />
                            </tr>
                            <tr
                              id="trBetterOdds"
                              style={{}}
                              className="BetBoxBg2"
                            >
                              <td>
                                <input type="checkbox" id="betChkBetterOdds" checked={currentAcBetter} onChange={changeAcBetter} />
                                &nbsp;
                                <span className="txtBetBold">
                                  ยอมรับราคาต่อรองที่ดีกว่า
                                </span>
                              </td>
                            </tr>
                            <tr style={{ height: 5 }}>
                              <td />
                            </tr>
                            <tr>
                              <td>
                                <table
                                  className="txtBet"
                                  width="100%"
                                  border={0}
                                  cellPadding={2}
                                  cellSpacing={2}
                                >
                                  <tbody>
                                    <tr>
                                      <td className="BetBoxBg4">
                                        การจ่ายเงินขั้นสู งสุด
                                      </td>
                                      <td className="BetBoxBg5" width="80px">
                                        <div align="right">
                                          &nbsp;
                                          <label
                                            id="betLblMaxPayout"
                                            style={{ display: "inline" }}
                                          >
                                            {formatMoney(max_pay, 2)}
                                          </label>
                                          &nbsp;
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="BetBoxBg4">
                                        พนันขั้นต่ำสุด
                                      </td>
                                      <td className="BetBoxBg5">
                                        <div align="right">
                                          &nbsp;
                                          <label
                                            id="betMinLimit"
                                            className="txtBlue"
                                          >
                                            {formatMoney(currentMinBet, 0)}
                                          </label>
                                          &nbsp;
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="BetBoxBg4">
                                        เดิมพันสูงสุด
                                      </td>
                                      <td className="BetBoxBg5">
                                        <div align="right">
                                          &nbsp;
                                          <label
                                            id="betMaxLimit"
                                            className="txtRed"
                                          >
                                            {formatMoney(currentMaxBet, 0)}
                                          </label>
                                          &nbsp;
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr style={{ height: 5 }}>
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr style={{ height: 5 }}>
              <td />
            </tr>
            <tr>
              <td align="center">
                <table width="164px" border={0} cellPadding={0} cellSpacing={0}>
                  <tbody>
                    <tr>
                      <td>
                        <div align="center">
                          <span style={{ cursor: "pointer" }}>
                            <img
                              id="betBtnBet"
                              alt=""
                              src={"/locales/TH/submit.jpg"}
                              onMouseOver={(e) =>
                                (e.currentTarget.src =
                                  "/locales/TH/submit2.jpg")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = "/locales/TH/submit.jpg")
                              }
                              onClick={saveBusket}
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <div align="center">
                          <span style={{ cursor: "pointer" }}>
                            <img
                              id="betBtnCancel"
                              alt=""
                              src={"/locales/TH/cancel.jpg"}
                              onMouseOver={(e) =>
                                (e.currentTarget.src =
                                  "/locales/TH/cancel2.jpg")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = "/locales/TH/cancel.jpg")
                              }
                              onClick={closeBet}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr style={{ height: 5 }}>
                      <td colSpan={2} />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {loadingInProgress ? (
        <div className="loader-container-bet">
          <ClipLoader color={'#d0bf70'} size={100} />
        </div>
      ) : (null)}
      
    </div>
  );
}

function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}

function getMinutesBetweenDates(startDate, endDate) {
  var diffMs = endDate - startDate;
  var diffSec = diffMs / 1000;
  return diffSec;
}