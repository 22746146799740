import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { usePrefixContext } from "../context/PrefixContext";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import img_a3 from "../Images/a3.jpg";
import img_a4 from "../Images/a4.jpg";

import SelMenu from "./SelMenu";
import TimeFunc from "./TimeFunc";
import apiBet from "../js/apiBet";
import api from "../js/api";
import axios from "axios";

export default function Top(props) {
  const { toogle_top, onToogle_top, onToogle_left, onPageMain , onSportsSelect , onSportsPriceSelect , onToogle_live , setVShow } = props;
  const [newsData, setNewsData] = useState([]);
  const { t } = useTranslation();
  const { getCurrentLanguage } = usePrefixContext();
  const { activeUser , logOut , setActUser } = useAuthContext();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      try {
        apiBet.defaults.headers.common["x-lang"] = getCurrentLanguage();
        await apiBet
          .get(`getNewsData`, { cancelToken: cancelToken.token })
          .then((res) => {
            //console.log(res.data.data);
            setNewsData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
      } catch (error) {
      }
    }
    async function fetchData2() {
      try {
        await api
          .get(`getPlayerWallet`, { cancelToken: cancelToken.token })
          .then((res) => {
            var old_act = activeUser;
            old_act["m_count"] = res.data.m_count;
            setActUser(old_act);
            //setNewsData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
      } catch (error) {
      }
    }
    fetchData();

    var cc_getm = setInterval(() => {
      fetchData2();
    }, (1000*5));

    return () => {
      cancelToken.cancel();
      clearInterval(cc_getm);
    };
  }, [getCurrentLanguage , activeUser , setActUser]);

  //console.log(activeUser);

  

  const handleToogle_top = () => {
    onToogle_top();
  };

  const handleToogle_left = () => {
    onToogle_left();
  };

  const handleClickLink = (value) => {
    onPageMain(value);
    if(value==="BallStep"){
      onSportsSelect(6);
      onSportsPriceSelect(4);
      onToogle_live(false);
    }else if(value==="Ball"){
      onSportsSelect(6);
      onSportsPriceSelect(1);
      onToogle_live(false);
    }else if(value==="AccHistory"){
      setVShow(0);
    }
  };

  return (
    <div className="topstyle">
      <div
        className={
          "trHeader" + (toogle_top === true ? " trHeader_toogle_top" : "")
        }
      >
        <div className="tHleft">
          <div className="tHLup">
            <div>
              <img
                alt=""
                src="/logo.png"
                height="45"
                style={{cursor:"pointer" , marginTop:"9.5px"}}
                onClick={() => handleClickLink("Ball")}
              />
            </div>
            <div>
              <div className="timelang">
                <div className="CssHdrTime">
                  <span id="timecontainer">{<TimeFunc />}</span>
                </div>
                <div>
                  <SelMenu />
                </div>
              </div>
            </div>
          </div>
          <div className="tHLbot">
            {/*<div style={{ width: "5px", height: "28px" }}></div>*/}
            <div className="HdrTabBga6L"></div>
            <div className="HdrTabBga6C">
              <Link className="ellipsis_header" to="/" onClick={() => handleClickLink("BallStep")}>
                {t("step")}
              </Link>
            </div>
            <div className="HdrTabBga6R"></div>
            <div style={{ width: "3px" }}></div>
            <div className="HdrTabBga6L"></div>
            <div className="HdrTabBga6C">
              <Link className="ellipsis_header" to="/" onClick={() => handleClickLink("AccDisable")}>
              {t("lotto")}
              </Link>
            </div>
            <div className="HdrTabBga6R"></div>
            <div style={{ width: "3px" }}></div>
            <div className="HdrTabBga6L"></div>
            <div className="HdrTabBga6C">
              <Link className="ellipsis_header" to="/" onClick={() => handleClickLink("AccDisable")}>
              {t("live_casino")}
              </Link>
            </div>
            <div className="HdrTabBga6R"></div>
            <div style={{ width: "3px" }}></div>
            <div className="HdrTabBga6L"></div>
            <div className="HdrTabBga6C">
              <Link className="ellipsis_header" to="/" onClick={() => handleClickLink("AccDisable")}>
              {t("games_hall")}
              </Link>
            </div>
            <div className="HdrTabBga6R"></div>
            <div style={{ width: "3px" }}></div>
            <div className="HdrTabBga6L"></div>
            <div className="HdrTabBga6C">
              <Link className="ellipsis_header" to="/" onClick={() => handleClickLink("AccDisable")}>
              {t("ufa_games")}
              </Link>
            </div>
            <div className="HdrTabBga6R"></div>
            <div style={{ width: "3px" }}></div>
            <div className="HdrTabBga6L"></div>
            <div className="HdrTabBga6C">
              <Link className="ellipsis_header" to="/" onClick={() => handleClickLink("AccDisable")}>
              {t("cockft")}
              </Link>
            </div>
            <div className="HdrTabBga6R"></div>
            <div style={{ width: "3px" }}></div>
            <div className="HdrTabBga6L"></div>
            <div className="HdrTabBga6C">
              <Link className="ellipsis_header" to="/" onClick={() => handleClickLink("AccDisable")}>
              {t("muay_step")}
              </Link>
            </div>
            <div className="HdrTabBga6R"></div>
            <div style={{ width: "3px" }}></div>
            <div className="HdrTabBga6L"></div>
            <div className="HdrTabBga6C">
              <Link className="ellipsis_header" to="/" onClick={() => handleClickLink("AccDisable")}>
              {t("esport")}
              </Link>
            </div>
            <div className="HdrTabBga6R"></div>
          </div>
        </div>
        <div className="tHright">
          <div className="blockUser">
            <div className="userCredit">
              <div>{t("welcome")}</div>
              <div>:</div>
              <div>
                <b>{activeUser !== null ? activeUser.m_name !== null && activeUser.m_name !== "" ? activeUser.m_name : activeUser.m_user : ""}</b>
              </div>
              <div>{t("credit")}</div>
              <div>:</div>
              <div>
                <b>
                  {activeUser !== null ? formatMoney(activeUser.m_count , 2) : ""}
                </b>
              </div>
            </div>
            <div className="btnBlock">
              <span className="class3"><Link
                to="/"
                onClick={() => handleClickLink("Preference")}
              >
                {t("preferences")}
              </Link>&nbsp;&nbsp;</span>
              <span
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontSize: "13px",
                }}
              >
                |
              </span>
              <span className="class3">&nbsp;&nbsp;<Link
                to="/"
                onClick={logOut}
              >
                {t("logout")}
              </Link></span>
            </div>
          </div>
        </div>
      </div>
      <div className="trBar">
        <div className="trBarGrid">
          <div></div>
          <div className="class2">
            <div className="class2Td">
              <Link
                className="ellipsis_bar spMargin"
                to="/"
                style={{ maxWidth: "85px" }}
                onClick={() => handleClickLink("Stake")}
              >
                {t("bet_list")}
              </Link>
              <Link
                className="ellipsis_bar spMargin"
                to="/"
                style={{ maxWidth: "77px" }}
                onClick={() => handleClickLink("AccHistory")}
              >
                {t("Statement")}
              </Link>
              <Link
                className="ellipsis_bar spMargin"
                to="/"
                style={{ maxWidth: "80px" }}
                onClick={() => handleClickLink("AccInfo")}
              >
                {t("my_account")}
              </Link>
              <Link
                className="ellipsis_bar spMargin"
                to="/"
                style={{ maxWidth: "120px" }}
                onClick={() => handleClickLink("Result")}
              >
                {t("result")}
              </Link>
            </div>
          </div>
          <div style={{ height: "26px" }}>
            <img
              alt=""
              src={img_a4}
              width="20"
              height="26"
              style={{ cursor: "pointer" }}
              onClick={handleToogle_left}
            />
          </div>
          <div style={{ height: "26px" }}>
            <img
              alt=""
              src={img_a3}
              width="20"
              height="26"
              style={{ cursor: "pointer" }}
              onClick={handleToogle_top}
            />
          </div>
          <div></div>
          <div className="HdrMsgBg">
            <div id="divMsg" style={{ width: "495px" , cursor: "pointer" }} className="CssHdrMsg" onClick={() => handleClickLink("News")}>
              <span
                id="spnMsg"
                style={{ animationDuration: "46s" }}
              >{(newsData[2]===99 ? <font color="red"><font size="2">{newsData[1]}</font></font> : newsData[1])}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}