import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrefixContext } from "../context/PrefixContext";

import img_mbModIcon from "../Images/mbModIcon.png";
import img_ft_close from "../Images/ft_close.gif";

export default function SelectTable(props) {
  const { onCloseSelectLeague, zone_all , zoneSelect , closeZoneSelect , onZoneSelect , onCloseZoneSelect } = props;
  const [openCheckedAll, setOpenCheckedAll] = useState(closeZoneSelect);
  const [listCheck, setListCheck] = useState(zoneSelect);
  const { t } = useTranslation();
  const prefixContext = usePrefixContext();
  const handleCloseSelectLeague = (value) => {
    setOpenCheckedAll(closeZoneSelect);
    setListCheck(zoneSelect);
    onCloseSelectLeague(value);
  };

  const handleOpenCheckAll = () => {
    setOpenCheckedAll(!openCheckedAll);
    //console.log(openCheckedAll);
    if(openCheckedAll===false){
      setListCheck([]);
    }else{
      var ddall = [];
      for (var ie in zone_all) {
        var die = zone_all[ie];
        ddall.push(die.zone_id);
      }
      setListCheck(ddall);
    }
  };

  const handleCheckAll = () => {
    if(openCheckedAll===false){
      setListCheck(zone_all.map(li => li.zone_id));
    }
  };

  const handleDeCheckAll = () => {
    if(openCheckedAll===false){
      setListCheck([]);
    }
  };

  const handleClick = (id , checked) => {
    setListCheck([...listCheck, id]);
    if (!checked) {
      setListCheck(listCheck.filter(item => item !== id));
    }
  };

  const handleOK = () => {
    onZoneSelect(listCheck);
    onCloseZoneSelect(openCheckedAll);
    onCloseSelectLeague("none");
  };

  var row_list = Math.ceil(zone_all.length / 3);

  var row_data = [];
  var index_use = 0;
  for (var i = 0; i < row_list; i++) {
    index_use = i;
    var data_in_row = [];
    for (var y = 0; y < 3; y++) {
      if (zone_all[index_use] !== undefined) {
        data_in_row.push(zone_all[index_use]);
      }
      index_use = index_use + row_list;
    }

    row_data.push(data_in_row);
  }

  //console.log(listCheck);

  return (
    <div className="popupW">
      <div className="mb_modTitle">
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              width: "25px",
              textAlign: "center",
            }}
          >
            <img alt="" src={img_mbModIcon} border="0" align="absmiddle" />
          </div>
          <div style={{ display: "table-cell", textAlign: "left" }}>
            {t("select_league")}
          </div>
          <div
            style={{
              display: "table-cell",
              width: "20px",
              textAlign: "center",
            }}
          >
            <img
              alt=""
              src={img_ft_close}
              style={{ cursor: "pointer" }}
              align="absmiddle"
              onClick={() => handleCloseSelectLeague("none")}
            />
          </div>
        </div>
      </div>
      <div style={{ background: "#f5f3f2" }}>
        <div
          style={{
            width: "95%",
            height: "100%",
            display: "table",
            margin: "auto",
          }}
        >
          <div style={{ display: "table-row", height: "10px" }}></div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", textAlign: "right" }}>
              <input
                name="chkAll"
                type="checkbox"
                id="chkAll"
                checked={openCheckedAll}
                onChange={handleOpenCheckAll}
              />
              <label htmlFor="chkAll">{t("select_all_without_filter")}</label>
              <input
                type="button"
                className="btn btnBlue btnSpec"
                value={t("select_all")}
                onClick={handleCheckAll}
              />
              <input
                type="button"
                className="btn btnBlue btnSpec"
                value={t("deselect_all")}
                onClick={handleDeCheckAll}
              />
              <input
                type="button"
                className="btn btnBlue btnSpec"
                value={t("ok")}
                id="btnOK"
                onClick={handleOK}
              />
            </div>
          </div>
          <div style={{ display: "table-row", height: "10px" }}></div>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell" }} className="tableGrid">
              <div
                style={{
                  display: "table",
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                {row_data.map(function (tlist, j) {
                  return (
                    <div style={{ display: "table-row" }} key={j}>
                      {tlist.map(function (tlistx, jj) {
                        var zone_show = {};
                        zone_show["CN"] = tlistx.zone_cn;
                        zone_show["EN"] = tlistx.zone_en;
                        zone_show["ES"] = tlistx.zone_en;
                        zone_show["FR"] = tlistx.zone_en;
                        zone_show["IN"] = tlistx.zone_en;
                        zone_show["JP"] = tlistx.zone_en;
                        zone_show["KH"] = tlistx.zone_en;
                        zone_show["KR"] = tlistx.zone_en;
                        zone_show["LA"] = tlistx.zone_en;
                        zone_show["MM"] = tlistx.zone_en;
                        zone_show["PT"] = tlistx.zone_en;
                        zone_show["TH"] = tlistx.zone_th;
                        zone_show["YN"] = tlistx.zone_en;
                        zone_show["RU"] = tlistx.zone_en;

                        return (
                          <div
                            style={{
                              display: "table-cell",
                              verticalAlign: "middle",
                            }}
                            key={jj}
                          >
                            <span className="Heading1">
                              <input
                                type="checkbox"
                                id={tlistx.zone_id}
                                checked={(openCheckedAll===true ? true : listCheck.includes(tlistx.zone_id))}
                                disabled={openCheckedAll}
                                onChange={() => handleClick(tlistx.zone_id , !listCheck.includes(tlistx.zone_id))}
                              />
                              <label htmlFor={tlistx.zone_id}>
                              {zone_show[prefixContext.getCurrentLanguage()]}
                              </label>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div style={{ display: "table-row", height: "15px" }}></div>
        </div>
      </div>
    </div>
  );
}